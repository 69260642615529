<template>
  <section class="main-body-wrapper">
    <div class="d-flex flex-column align-items-center text-left">
      <div class="page-title">
        <h1 v-if="!smsSent && !reEnterNumber">Enter your mobile number</h1>
        <h1 v-else-if="reEnterNumber">Re-enter a different number</h1>
        <h1 v-else>Verify your mobile number</h1>
        <!-- description -->
        <p class="description mt-3" v-if="!smsSent">
          We'll text this number to verify your account. Message and data rates may apply.
        </p>
        <p class="description mt-3" v-else>
          Please enter the 6-digit verification code sent to your phone number ending in
          {{ mobile.slice(-4) }} to continue.
        </p>
      </div>
    </div>
    <div class="form-wrapper" style="padding-bottom: 10px; margin-top: 4px">
      <div class="container-fluid">
        <div class="pb-3" v-show="!smsSent">
          <form @submit.prevent="!resend ? sendSms() : resendSms()" ref="form">
            <div class="mobile-input-wrapper">
              <BasePhoneInput
                ref="input-phone"
                label=""
                name="mobile"
                required
                :error="getError('mobile')"
                v-model="mobile"
                v-validate="modelValidations.mobile"
              />
            </div>
            <div class="continue-btn-wrap text-center">
              <button
                ref="submit-button"
                v-show="!smsSent"
                :disabled="sendingSms"
                type="submit"
                class="btn continue-button mt-3"
              >
                {{ buttonText }} {{ waitTime > 0 ? `in ${countDown}` : '' }}
              </button>
              <label for="mobile-number" class="resendcode mt-3">
                <span>{{ sendingMessage }}</span>
              </label>
            </div>
          </form>
        </div>
        <div v-show="smsSent">
          <div class="w-100 py-1 text-center">
            <div class="otp-section">
              <h2 class="text-left" style="font-size: 22px; font-weight: 400">
                Verification code
              </h2>
              <form class="otp-box-wrapper text-center" @submit.prevent="next" ref="otpForm">
                <div class="otp-input-wrapper">
                  <input
                    v-for="(digit, index) in otpDigits"
                    :key="index"
                    :ref="`input-otp-${index}`"
                    v-model="otpDigits[index]"
                    type="text"
                    maxlength="1"
                    class="otp-input"
                    @input="onOtpInput(index, $event)"
                    @keydown="onOtpKeyDown(index, $event)"
                    @paste="onOtpPaste"
                  />
                </div>
                <div class="otp-actions">
                  <button
                    type="button"
                    :disabled="waitTime > 0"
                    class="btn-link"
                    @click="resendSms"
                  >
                    <i class="fa fa-mobile-alt mr-2 ml-1"></i> Resend code
                    {{ waitTime > 0 ? ` in ${countDown}` : '' }}
                  </button>
                  <button type="button" class="btn-link" @click="reEnterDifferentNumber">
                    <i class="fa fa-chevron-left mr-2 ml-1" style="font-size: 12px"></i>
                    Enter a different number
                  </button>
                </div>
                <div class="w-100 otp-submit-btn">
                  <div class="continue-btn-wrap text-center">
                    <button
                      ref="verify-button"
                      type="submit"
                      class="btn continue-button mt-3"
                      :disabled="this.smsCode.length < 6 || isSubmitting"
                    >
                      {{ isSubmitting ? 'Processing...' : 'Continue' }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { validPhoneFormat } from '@/helpers/CustomValidations';
import { mapGetters } from 'vuex';
import { formControlMixin } from '@/mixins/FormControlMixin';
import BasePhoneInput from '../components/BasePhoneInput.vue';
import config from '../config.js';
import { ENVIRONMENTS } from '../helpers/constants.js';

export default {
  name: 'MobileCodeVerification',
  components: { BasePhoneInput },
  mixins: [formControlMixin],
  $_veeValidate: { validator: 'new' },
  data() {
    return {
      modelValidations: {
        mobile: { required: true, validPhoneFormat: true }
      },
      waitTime: 0,
      otpValue: '',
      buttonText: 'Send Code',
      smsSent: false,
      sendingSms: false,
      isSubmitting: false,
      sendingMessage: 'An SMS message will be sent with the confirmation code.',
      mobile: '',
      smsReceived: false,
      smsCode: 0,
      otp: [],
      error: null,
      resend: false,
      windowWidth: window.innerWidth,
      otpDigits: Array(6).fill(''),
      reEnterNumber: false
    };
  },
  computed: {
    ...mapGetters({ invite: 'getInvite', isBackButtonPressed: 'isBackButtonPressed' }),
    countDown() {
      const seconds = Math.floor(this.waitTime / 1000);
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    }
  },
  created() {
    this.$validator.extend(validPhoneFormat.name, validPhoneFormat.obj);
  },
  mounted() {
    if (this.invite?.phoneNumber) this.mobile = this.invite.phoneNumber;
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
      this.focusNext(null, 'input-phone');
    });
    if (config.ENV !== ENVIRONMENTS.PROD && !this.invite?.phoneNumber) {
      this.mobile = Math.floor(2234000000 + Math.random() * 900000).toString();
    }
    localStorage.setItem('mobile', '');
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  watch: {
    isBackButtonPressed(newVal) {
      if (newVal) {
        this.back();
      }
    },
    smsSent(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.focusNext(null, 'input-otp-0');
        });
      }
    }
  },
  methods: {
    async codepreview() {
      this.buttonText = 'Resend SMS';
      this.sendingMessage = 'An SMS message will be sent with the confirmation code.';
      this.smsReceived = false;
      this.smsSent = false;
      this.resend = true;
      await this.$validator.reset();
      this.error = false;
    },
    back() {
      if (this.smsSent) {
        this.smsSent = false;
        this.sendingMessage = 'An SMS message will be sent with the confirmation code.';
        this.otpDigits = Array(6).fill('');
        return;
      }
      this.$router.push({ name: 'GetStarted' }).catch(() => {});
    },
    reEnterDifferentNumber() {
      this.smsSent = false;
      this.reEnterNumber = true;
      this.sendingMessage = 'Please enter a different mobile number.';
      this.$nextTick(() => {
        this.focusNext(null, 'input-phone');
      });
      this.otpDigits = Array(6).fill('');
    },
    async next() {
      if (this.isSubmitting) return;

      this.isSubmitting = true;
      try {
        await this.smsVerification();
      } catch (error) {
        console.error('SMS verification failed:', error);
        this.$toasted.error('Verification failed. Please try again.');
      } finally {
        this.isSubmitting = false;
      }
    },
    async smsVerification() {
      const data = { smsCode: this.smsCode };
      const settings = {
        url: '/onboarding/accounts/sms',
        method: 'POST',
        processData: false,
        data
      };
      try {
        const response = await this.$axios(settings);
        if (response.data.status == 'valid') {
          this.$store.commit('MARK_STEP_COMPLETED', 1);
          await this.$router
            .push({
              name: 'MerchantType',
              query: this.$route.query,
              replace: true
            })
            .catch(() => {});
        } else {
          this.$store.commit('LOG_ERROR', { errorMessage: 'Invalid SMS code.' });
          this.error = true;
        }
      } catch (e) {
        const errorStatus = e.response.status;
        const errorMessage = e.response.data.message
          ? e.response.data.message
          : 'Server-side error, please contact the support team.';
        const logObject = {
          module: 'onboarding',
          error: errorMessage,
          details: {
            page: 'mobileCodeVerification.smsVerification',
            endpoint: '/onboarding/accounts/sms',
            data
          }
        };
        this.$store.commit('LOG_ERROR', { errorMessage, errorStatus });
      }
    },
    setCountDown(waitTime, errorMessage) {
      this.buttonText = 'Resend SMS';
      if (errorMessage) this.sendingMessage = errorMessage;
      if (waitTime) {
        this.sendingSms = true;
        this.waitTime = waitTime;
        setTimeout(() => {
          this.sendingSms = false;
        }, waitTime);
        this.waitTimeInterval = setInterval(() => {
          if (this.waitTime > 1000) {
            this.waitTime -= 1000;
          } else {
            this.waitTime = 0;
            this.sendingSms = false;
            this.sendingMessage =
              'An SMS message will be sent with the confirmation code.';
            this.buttonText = 'Resend SMS';
            clearInterval(this.waitTimeInterval);
          }
        }, 1000);
      }
      return;
    },
    async sendSms() {
      const sendSmsParams = {
        mobileNumber: this.mobile,
        spProductSlug: this.$route.params.spProductSlug
      };
      if (this.resend) {
        sendSmsParams.resend = true;
      }
      const isValid = await this.$validator.validateAll();
      if (!isValid) {
        this.$toasted.show('There are form errors, please check.', {
          type: 'error',
          duration: 3000,
          position: 'top-right'
        });
        return;
      }
      try {
        this.sendingSms = true;
        this.sendingMessage = 'SMS sent, please wait...';
        localStorage.setItem('mobile', this.mobile);
        const response = await this.$axios({
          url: '/onboarding/accounts/sms/sendsms',
          method: 'POST',
          data: sendSmsParams
        });
        this.sendingSms = false;

        const { data } = response;
        if (data.success) {
          const { smsCode } = response.data;
          if (smsCode) {
            this.smsCode = smsCode;
            this.otpDigits = smsCode.split('');
          }
          if (this.resend) this.resend = false;
          if (data.waitTime) this.setCountDown(data.waitTime);
          // this.$store.dispatch('SET_OVERRIDE_BACK_BUTTON', true);
          this.$nextTick(() => {
            if (this.$refs.otpInput && this.$refs.otpInput.length > 0) {
              this.$refs.otpInput[0].focus();
            }
          });
        }
        this.smsSent = true;
      } catch (error) {
        if (error.response.status === 429) {
          this.setCountDown(error.response.data.waitTime, error.response.data.message);
        }
        const errorMessage = error.response?.data?.message
          ? error.response.data.message
          : 'Server-side error, please contact the support team.';
        const logObject = {
          module: 'onboarding',
          error: errorMessage,
          details: {
            page: 'mobileCodeVerification.sendsms',
            endpoint: '/onboarding/accounts/sms/sendsms',
            data: sendSmsParams
          }
        };
        console.log(JSON.stringify(logObject));
        this.$store.commit('LOG_ERROR', { errorMessage });
      } finally {
        this.smsSent = true;
        this.sendingSms = false;
        this.resend = false;
      }
    },
    async resendSms() {
      this.resend = true;
      await this.sendSms();
    },
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    toggleTerms() {
      this.$store.dispatch('TOGGLE_TERMS', Date.now());
    },
    togglePrivacy() {
      this.$store.dispatch('TOGGLE_PRIVACY', Date.now());
    },
    onOtpInput(index, event) {
      const digit = event.target.value;
      if (/^\d$/.test(digit)) {
        this.otpDigits.splice(index, 1, digit);
        if (index < 5) {
          this.focusNext(`input-otp-${index}`, `input-otp-${index + 1}`);
        }
      } else {
        this.otpDigits.splice(index, 1, '');
      }
      this.smsCode = this.otpDigits.join('');
    },
    onOtpKeyDown(index, event) {
      const keyHandlers = {
        Backspace: () => {
          if (!this.otpDigits[index] && index > 0) {
            this.focusNext(`input-otp-${index}`, `input-otp-${index - 1}`);
          }
        },
        Delete: () => {
          this.otpDigits.splice(index, 1, '');
          this.$nextTick(() => {
            const nextIndex = Math.min(index + 1, this.otpDigits.length - 1);
            this.focusNext(`input-otp-${index}`, `input-otp-${nextIndex}`);
          });
        },
        ArrowLeft: () => index > 0 && this.focusNext(`input-otp-${index}`, `input-otp-${index - 1}`),
        ArrowRight: () => index < 5 && this.focusNext(`input-otp-${index}`, `input-otp-${index + 1}`),
        Enter: () => {
          event.preventDefault();
          if (this.smsCode.length === 6) {
            this.next();
          }
        }
      };

      const handler = keyHandlers[event.key];
      if (handler) {
        if (event.key !== 'Backspace') {
          event.preventDefault();
        }
        handler();
      }
    },
    onOtpPaste(event) {
      event.preventDefault();
      const pastedCode = event.clipboardData.getData('text/plain').slice(0, 6);
      if (/^\d{1,6}$/.test(pastedCode)) {
        this.otpDigits.splice(0, pastedCode.length, ...pastedCode.split(''));
        this.$nextTick(() => {
          const lastIndex = pastedCode.length - 1;
          if (lastIndex < 5) {
            this.focusNext(`input-otp-${lastIndex}`, `input-otp-${lastIndex + 1}`);
          }
        });
      }
      this.smsCode = this.otpDigits.join('');
    }
  }
};
</script>

<style lang="scss" scoped>
.sms_code {
  font-size: 64px;
  text-align: center;
}

#mobile-input {
  .success-icon {
    top: 23px !important;
    position: absolute;
    right: 10px;
    font-size: 10px;
  }
}

button.continue-btn {
  padding: 12px 68px;
  width: 233px !important;
}

@media only screen and (max-width: 475px) {
  .mobile-input-wrapper {
    margin-top: 26px !important;
    margin-bottom: -39px !important;
  }
}

.otp-box-wrapper {
  width: 100% !important;

  input {
    width: 100%;
  }
}

.mobile-input-wrapper {
  margin-top: 1px;
  margin-bottom: -16px;
  min-width: 252px !important;
}

.page-title {
  max-width: 450px;
  width: 100%;
  margin-bottom: 10px;
}

.otp-input-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;

  .otp-input {
    flex: 1;
    max-width: 40px;
    height: 40px;
    text-align: center;
    padding: 0px !important;
    padding-left: 5px !important;
    font-size: 24px;
    border: none;
    border-bottom: 2px solid #ccc;
    outline: none;
    border-radius: 0 !important;

    &:focus {
      border-bottom-color: var(--main-primary-color-darker, unset);
    }
  }
}

.otp-section {
  .otp-box-wrapper {
    // margin-top: 20px;
    .otp-input-wrapper {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }
    .otp-input {
      flex: 1;
      max-width: 40px;
      height: 40px;
      text-align: center;
      margin: 0 5px;
      font-size: 24px;
      border: none;
      border-bottom: 2px solid #ccc;
      &:focus {
        border-bottom-color: var(--main-primary-color-darker, unset);
      }
    }
    .resend-code {
      text-align: center;
    }
  }
  .otp-actions {
    display: flex;
    gap: 16px;

    button.btn-link {
      background: none;
      border: 1px solid black;
      border-radius: 100px;
      // color: #007bff;
      color: inherit;
      cursor: pointer;
      font-size: 14px;
      padding: 0px 10px;
      height: 40px;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.disabled-link {
  pointer-events: none;
  cursor: default;
  color: #ccc;
  text-decoration: none;
  opacity: 0.5;
}
</style>
