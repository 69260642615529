import Vue from 'vue';
import Router from 'vue-router';
import Bowser from 'bowser';
import store from '../store';

import GetStarted from '@/pages/GetStarted';
import InitiateFailure from '@/pages/InitiateFailure';
import UnsupportedBrowser from '@/pages/UnsupportedBrowser';
import AccountApproved from '@/pages/AccountApproved';
import AccountNotApproved from '@/pages/AccountNotApproved';
import ApplicationSaved from '@/pages/ApplicationSaved';
import BankForm from '@/pages/BankForm';
import Checks from '@/pages/Checks';
import CompanyForm from '@/pages/CompanyForm';
import ConfirmProfile from '@/pages/ConfirmProfile';
import ContinueAccount from '@/pages/ContinueAccount';
import InviteAlreadySubmited from '@/pages/InviteAlreadySubmited';
import InviteExpired from '@/pages/InviteExpired';
import MerchantType from '@/pages/MerchantType';
import MobileCodeVerification from '@/pages/MobileCodeVerification';
import VerifyOwnership from '@/pages/VerifyOwnership';
import BaseLayout from '../components/BaseLayout.vue';
import Footer from '../components/Footer.vue';
import Header from '../components/Header.vue';
import { debounce } from '../helpers/utils';
import checkProductStatus from './middlewares/checkProductStatus';

Vue.use(Router);

const browser = Bowser.getParser(window.navigator.userAgent);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'InitiateFailure',
      component: InitiateFailure
    },
    {
      path: '/unsupported',
      name: 'UnsupportedBrowser',
      component: UnsupportedBrowser,
      beforeEnter: (to, from, next) => {
        if (browser.getBrowserName() !== 'Internet Explorer') return next('/');
        next();
      }
    },
    {
      path: '/:spProductSlug',
      component: BaseLayout,
      beforeEnter: (to, from, next) => {
        if (!store.getters.isAuthenticated && to.name !== 'GetStarted')
          return next({ name: 'GetStarted', params: to.params });
        else next();
      },
      children: [
        {
          path: 'sms',
          name: 'MobileCodeVerification',
          components: {
            default: MobileCodeVerification,
            Footer,
            Header
          },
          meta: {
            middleware: [checkProductStatus],
            stepIndex: 0
          }
        },
        {
          path: 'account-type',
          name: 'MerchantType',
          components: {
            default: MerchantType,
            Footer,
            Header
          },
          meta: {
            middleware: [checkProductStatus],
            stepIndex: 1
          }
        },
        {
          path: 'account-company',
          name: 'CompanyForm',
          components: {
            default: CompanyForm,
            Footer,
            Header
          },
          meta: {
            middleware: [checkProductStatus],
            stepIndex: 1
          }
        },
        {
          path: 'verify-ownership',
          name: 'VerifyOwnership',
          components: {
            default: VerifyOwnership,
            Footer,
            Header
          },
          meta: {
            middleware: [checkProductStatus],
            stepIndex: 2
          }
        },
        {
          path: 'bank-account',
          name: 'BankForm',
          components: {
            default: BankForm,
            Footer,
            Header
          },
          meta: {
            middleware: [checkProductStatus],
            stepIndex: 3
          }
        },
        {
          path: 'reviewing',
          name: 'Checks',
          components: {
            default: Checks,
            Footer
          },
          props: true
        },
        {
          path: 'confirm',
          name: 'ConfirmProfile',
          components: {
            default: ConfirmProfile,
            Footer,
            Header
          },
          meta: {
            middleware: [checkProductStatus],
            stepIndex: 4
          }
        },
        {
          path: 'account-approved',
          name: 'AccountApproved',
          components: {
            default: AccountApproved,
            Footer
          },
          meta: { requiresAuth: true }
        },
        {
          path: 'account-for-review',
          name: 'AccountNotApproved',
          component: AccountNotApproved
        },
        {
          path: 'saved',
          name: 'ApplicationSaved',
          components: {
            default: ApplicationSaved,
            Footer
          }
        },
        {
          path: 'get-started',
          name: 'GetStarted',
          components: {
            default: GetStarted,
            Footer,
            Header
          },
          meta: {
            middleware: [checkProductStatus],
            stepIndex: 0
          }
        },
        {
          path: '',
          name: 'EmptyWelcome',
          components: {
            default: GetStarted,
            Footer,
            Header
          },
          meta: {
            middleware: [checkProductStatus]
          },
          beforeEnter: (to, from, next) => {
            next({
              name: 'GetStarted',
              params: { spProductSlug: to.params.spProductSlug },
              query: to.query
            });
          }
        },
        {
          path: 'continue',
          name: 'continue',
          components: {
            default: ContinueAccount,
            Footer
          },
          meta: {
            middleware: [checkProductStatus]
          }
        },
        {
          path: 'invite-expired',
          name: 'InviteExpired',
          components: {
            default: InviteExpired,
            Footer
          }
        },
        {
          path: 'invite-already-submitted',
          name: 'InviteAlreadySubmited',
          components: {
            default: InviteAlreadySubmited,
            Footer
          }
        },
        { path: '*', redirect: '/:spProductSlug' }
      ]
    }
  ]
});

// Debounce navigation to prevent rapid consecutive navigations
const pendingNavigation = { value: false };
const safeNavigate = debounce(async (to, from, next) => {
  if (pendingNavigation.value) return;

  pendingNavigation.value = true;
  try {
    await next();
  } catch (err) {
    if (err.name !== 'NavigationDuplicated') {
      console.error('Navigation error:', err);
    }
  } finally {
    pendingNavigation.value = false;
  }
}, 300);

// Global Navigation Guard
router.beforeEach(async (to, from, next) => {
  const { hasSignificantChanges, lastSignificantChangeStep, steps } = store.state;

  // Special routes that bypass normal navigation logic
  const specialRoutes = [
    'ContinueWithSmApplicationId',
    'continue',
    'ApplicationSaved',
    'Checks',
    'AccountApproved',
    'AccountNotApproved',
    'UnsupportedBrowser',
    'InviteExpired',
    'InviteAlreadySubmited',
    'MerchantType'
  ];

  if (
    to.params._normalPush === true ||
    specialRoutes.includes(to.name) ||
    ['ContinueWithSmApplicationId', 'continue'].includes(from.name)
  ) {
    await store.dispatch('RESET_SIGNIFICANT_CHANGES');
    return next();
  }

  const targetStepIndex = steps.findIndex(step => step.routeNames.includes(to.name));
  const lastVisitedIndex = steps.reduce(
    (lastIndex, step, index) => (step.isVisited ? index : lastIndex),
    -1
  );

  if (targetStepIndex === -1) {
    if (to.params?.spProductSlug) {
      return next({
        name: 'GetStarted',
        params: { spProductSlug: to.params.spProductSlug },
        query: to.query
      });
    }
    if (from.name || to.name === 'InitiateFailure') {
      return next();
    }
  }

  const lastAllowedStep = steps[lastVisitedIndex];

  // Navigation logic
  if (hasSignificantChanges && targetStepIndex > lastSignificantChangeStep) {
    return next({
      name: steps[lastSignificantChangeStep].routeNames[0],
      params: to.params
    });
  }

  if (!hasSignificantChanges && targetStepIndex > lastVisitedIndex + 1) {
    return next({ name: lastAllowedStep.routeNames[0], params: to.params });
  }

  if (targetStepIndex <= lastVisitedIndex || targetStepIndex === lastVisitedIndex + 1) {
    await store.dispatch('RESET_SIGNIFICANT_CHANGES');
    return safeNavigate(to, from, next);
  }

  if (lastAllowedStep) {
    return next({ name: lastAllowedStep.routeNames[0], params: to.params });
  }

  return next();
});

export default router;
