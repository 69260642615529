import { DateTime } from 'luxon';
import { PRETTY_DATE_FORMAT } from './constants';

export const mustHaveStreetNumber = {
  name: 'mustHaveStreetNumber',
  obj: {
    getMessage: () => 'Please enter street number at the beginning',
    validate: val => {
      if (!val || typeof val !== 'string') return false;

      // Split by first space to get the potential street number
      const firstPart = val.trim().split(/\s+/)[0];

      // Check if the first part contains at least one digit
      return /\d/.test(firstPart);
    }
  }
};

export const mustHaveStreetName = {
  name: 'mustHaveStreetName',
  obj: {
    getMessage: () => 'Please enter street name',
    validate: val => {
      if (!val || typeof val !== 'string') return false;
      const parts = val.split(' ');
      return parts.length > 1 && parts[1].length > 0;
    }
  }
};

export const validUrl = {
  name: 'validUrl',
  obj: {
    getMessage: () => 'Please enter a valid URL',
    validate: url => {
      if (!url) return false;
      if (url.trim() === '') return true;

      // Remove leading/trailing whitespace
      url = url.trim();

      // Check if the URL already starts with http:// or https://
      if (!url.startsWith('http://') && !url.startsWith('https://')) {
        url = `http://${url}`;
      }

      const res = url.match(
        /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w-]+)+[\w\-_~:/?#[\]@!$&'()*+,;=]+$/gm
      );
      return res !== null;
    }
  }
};

export const dateLessThanTomorrow = {
  name: 'dateLessThanTomorrow',
  obj: value => {
    if (!value) return true;
    const date = DateTime.fromFormat(value, PRETTY_DATE_FORMAT);
    if (!date.isValid) return false;
    return date.startOf('day') <= DateTime.now().startOf('day');
  }
};

export const validDateFormat = {
  name: 'validDateFormat',
  obj: value => {
    if (!value) return true;
    return DateTime.fromFormat(value, PRETTY_DATE_FORMAT).isValid;
  }
};

export const validBirthDate = {
  name: 'validBirthDate',
  obj: date => {
    if (!date) return true;

    // Try both date formats
    let parsedDate = DateTime.fromFormat(date, 'MM-dd-yyyy', { zone: 'utc' });
    if (!parsedDate.isValid) {
      parsedDate = DateTime.fromFormat(date, 'yyyy-MM-dd', { zone: 'utc' });
    }

    if (!parsedDate.isValid) return false;

    const diff = DateTime.now().diff(parsedDate, 'years').years;
    return diff >= 18 && diff <= 100;
  }
};

export const validzipcodeRegex = /^\d{5}$/;

export const validfederalTaxId = /^\d{2}-?\d{7}$/;

export const validSSN = /^\d{3}-?\d{2}-?\d{4}$/;

export const validPhoneFormat = {
  name: 'validPhoneFormat',
  obj: {
    getMessage: () => 'Please enter a valid phone',
    validate: val => {
      if (!val) return false;
      return val !== 'invalid format';
    }
  }
};
