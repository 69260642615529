<template>
  <div
    class="form-group mb-3"
    :class="{
      'input-group-focus': focused,
      'has-label': label
    }"
  >
    <slot name="label">
      <label v-if="label" class="input-label d-flex" :style="labelStyle">
        <i v-if="!required" class="optional-field float-left" />
        <span>{{ label }}</span>
      </label>
    </slot>
    <div
      class="input-wrapper mb-0"
      :class="{
        'input-group': hasIcon
      }"
    >
      <slot name="addonLeft">
        <span v-if="addonLeftIcon" class="input-group-prepend">
          <div
            class="input-group-text"
            :class="{
              'error-brdr': error,
              'success-brdr': !error && touched
            }"
          >
            <i :class="addonLeftIcon"></i>
          </div>
        </span>
      </slot>
      <slot>
        <input
          :id="inputId"
          :name="$attrs.name"
          ref="input"
          type="tel"
          class="form-control"
          :class="{
            'error-brdr': error,
            'success-brdr': hasValidation && !error && touched
          }"
          :value="phone"
          v-maska="inputMask"
          v-focus="focus"
          v-bind="$attrs"
          v-on="listeners"
          placeholder="(555) 555-5555"
        />
      </slot>
      <span class="success-icon" v-if="hasValidation && !error && touched">
        <i class="fa fa-check-circle" aria-hidden="true"></i>
      </span>
      <span class="error-icon" v-else-if="error">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
      </span>
    </div>
    <slot name="error" v-if="error || $slots.error">
      <i class="error-msg">{{ error }}</i>
    </slot>
  </div>
</template>

<script>
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.min.css';

const INVALID_FORMAT = 'invalid format';
const US_CA_MASK = '+1 (###) ###-####';
const AR_MASK = '+54 ### ##-####-####';

export default {
  inheritAttrs: false,
  name: 'BasePhoneInput',
  props: {
    labelStyle: String,
    focus: Boolean,
    required: Boolean,
    label: { type: String },
    error: { type: String, default: '' },
    value: { type: [String, Number] },
    addonLeftIcon: { type: String, description: 'Input icon on the left' },
    hasValidation: { type: Boolean, default: true }
  },
  data() {
    return {
      focused: false,
      touched: false,
      inputId: `i${Math.floor(10000 + Math.random() * 900).toString()}`,
      iti: {},
      phone: null,
      firstLoad: true,
      scriptIsLoaded: false,
      inputMask: US_CA_MASK
    };
  },
  computed: {
    hasIcon() {
      const { addonLeft } = this.$slots;
      return addonLeft !== undefined || this.addonLeftIcon !== undefined;
    },
    listeners() {
      return {
        ...this.$listeners,
        input: this.onInput,
        blur: this.onBlur,
        focus: this.onFocus
      };
    }
  },
  async mounted() {
    const input = document.querySelector(`#${this.inputId}`);
    this.iti = intlTelInput(input, {
      utilsScript: '/static/js/phoneLibUtils.min.js',
      formatOnDisplay: false,
      onlyCountries: ['us'],
      dropdownContainer: document.body,
      initialCountry: 'us',
      allowDropdown: false
    });
    input.addEventListener('countrychange', () => {
      this.updateMask();
      this.onInput();
    });
    await this.iti.promise;
    this.scriptIsLoaded = true;
    this.setPhone();
  },
  methods: {
    onInput(evt) {
      if (!this.touched) this.touched = true;
      this.validateAndEmitOutput();
      if (evt && evt.target) this.phone = evt.target.value;
    },
    validateAndEmitOutput() {
      let output = null;
      if (this.iti.getNumber() && this.iti.getNumber().trim()) {
        if (this.iti.isValidNumber()) {
          let phoneNumber = this.iti.getNumber(
            // eslint-disable-next-line no-undef
            intlTelInputUtils.numberFormat.INTERNATIONAL
          );
          const firstSpaceIdx = phoneNumber.indexOf(' ');
          phoneNumber = phoneNumber.substring(firstSpaceIdx + 1);
          phoneNumber = phoneNumber
            .replace(new RegExp(' ', 'g'), '')
            .replace(new RegExp('-', 'g'), '');
          output = phoneNumber;
        } else output = INVALID_FORMAT;
      }
      this.$emit('input', output);
    },
    updateMask() {
      const selectedCountry = this.iti.getSelectedCountryData();
      this.inputMask =
        selectedCountry && selectedCountry.iso2 === 'ar' ? AR_MASK : US_CA_MASK;
    },
    onFocus() {
      this.focused = true;
    },
    onBlur() {
      this.focused = false;
      this.$emit('blur');
    },
    reset() {
      this.touched = false;
    },
    setFocus() {
      this.$refs.input.focus();
    },
    setPhone() {
      if (!(typeof this.value === 'string' && this.value)) {
        return;
      }
      let formattedNumber = this.value.replace(/\D/g, '');
      if (formattedNumber.startsWith('1')) {
        formattedNumber = formattedNumber.substring(1);
      }

      this.phone = formattedNumber.length >= 10
        ? `(${formattedNumber.substring(0, 3)}) ${formattedNumber.substring(3, 6)}-${formattedNumber.substring(6, 10)}`
        : formattedNumber;

      this.iti.setNumber(`+1${formattedNumber}`);
      this.updateMask();
      this.validateAndEmitOutput();
      this.firstLoad = false;
    }
  },
  watch: {
    value(newValue) {
      if (this.scriptIsLoaded && this.firstLoad && newValue !== INVALID_FORMAT)
        this.setPhone();
    }
  },
  directives: {
    focus: {
      inserted: function (el, { value }, { context }) {
        context.$nextTick(() => {
          if (value) el.focus();
        });
      }
    }
  }
};
</script>

<style scoped>
input {
  padding-left: 48px !important;
  height: 40px !important;
  max-height: 40px !important;
}
input::placeholder {
  opacity: 1;
  color: rgb(191, 191, 191);
}
.error {
  display: block;
  width: 100%;
  font-size: 80%;
  color: #ff1721 !important;
  margin-top: 5px;
}
:deep(.iti) {
  width: 100%;
}
:deep(.iti__flag-container) {
  right: auto;
  left: 0;
}
:deep(.iti__selected-flag) {
  padding: 0 8px 0 12px;
}
:deep(.iti--allow-dropdown input) {
  padding-right: 12px;
  padding-left: 48px;
}
</style>
