import { US_STATES } from '../helpers/constants';

const STATE_MAP = {
  'AL': 'Alabama', 'AK': 'Alaska', 'AZ': 'Arizona', 'AR': 'Arkansas',
  'CA': 'California', 'CO': 'Colorado', 'CT': 'Connecticut', 'DE': 'Delaware',
  'FL': 'Florida', 'GA': 'Georgia', 'HI': 'Hawaii', 'ID': 'Idaho',
  'IL': 'Illinois', 'IN': 'Indiana', 'IA': 'Iowa', 'KS': 'Kansas',
  'KY': 'Kentucky', 'LA': 'Louisiana', 'ME': 'Maine', 'MD': 'Maryland',
  'MA': 'Massachusetts', 'MI': 'Michigan', 'MN': 'Minnesota', 'MS': 'Mississippi',
  'MO': 'Missouri', 'MT': 'Montana', 'NE': 'Nebraska', 'NV': 'Nevada',
  'NH': 'New Hampshire', 'NJ': 'New Jersey', 'NM': 'New Mexico', 'NY': 'New York',
  'NC': 'North Carolina', 'ND': 'North Dakota', 'OH': 'Ohio', 'OK': 'Oklahoma',
  'OR': 'Oregon', 'PA': 'Pennsylvania', 'RI': 'Rhode Island', 'SC': 'South Carolina',
  'SD': 'South Dakota', 'TN': 'Tennessee', 'TX': 'Texas', 'UT': 'Utah',
  'VT': 'Vermont', 'VA': 'Virginia', 'WA': 'Washington', 'WV': 'West Virginia',
  'WI': 'Wisconsin', 'WY': 'Wyoming', 'DC': 'District of Columbia'
};

export const addressMixin = {
  data() {
    return {
      addressLoading: false,
      selectedAddress: null
    };
  },

  computed: {
    stateOptions() {
      return US_STATES.map(abbr => ({
        value: abbr,
        label: `${STATE_MAP[abbr]} (${abbr})`
      }));
    }
  },

  methods: {
    async handleAddressSearch(query) {
      if (!query) return [];

      this.addressLoading = true;
      try {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(query)}&countrycodes=us&format=json&addressdetails=1&limit=5`,
          {
            headers: {
              'Accept-Language': 'en-US,en;q=0.9'
            }
          }
        );

        if (!response.ok) throw new Error('Failed to fetch addresses');
        const data = await response.json();

        return data.map(item => ({
          label: item.display_name,
          value: item,
          disabled: false
        }));
      } catch (error) {
        console.error('Address lookup failed:', error);
        return [];
      } finally {
        this.addressLoading = false;
      }
    },

    formatAddress(address) {
      const streetNumber = address.house_number || '';
      const streetName = address.road || '';
      return `${streetNumber} ${streetName}`.trim();
    },

    getStateLabel(abbr) {
      return STATE_MAP[abbr] || abbr;
    },

    getStateAbbr(fullName) {
      if (!fullName) return '';

      // First check if it's already an abbreviation
      const upperFullName = fullName.toUpperCase();
      if (STATE_MAP[upperFullName]) {
        return upperFullName;
      }

      // Then look for the full name
      const entry = Object.entries(STATE_MAP).find(([_, name]) =>
        name.toLowerCase() === fullName.toLowerCase()
      );
      return entry ? entry[0] : '';
    },

    filterStates(query) {
      if (!query) return this.stateOptions;
      query = query.toLowerCase().trim();

      // Common state variations for quick matching
      const stateVariations = {
        'calif': 'CA', 'cali': 'CA',
        'mass': 'MA',
        'penn': 'PA',
        'york': 'NY',
        'jersey': 'NJ',
        'tex': 'TX',
        'tenn': 'TN',
        'wash': 'WA',
        'virg': 'VA',
        'carol': ['NC', 'SC'],
        'dakota': ['ND', 'SD'],
        'virginia': ['VA', 'WV']
      };

      // Check for variation matches first
      for (const [variation, states] of Object.entries(stateVariations)) {
        if (query.includes(variation)) {
          if (Array.isArray(states)) {
            return this.stateOptions.filter(state => states.includes(state.value));
          }
          return this.stateOptions.filter(state => state.value === states);
        }
      }

      // Then do regular matching
      return this.stateOptions.filter(state => {
        const stateAbbr = state.value.toLowerCase();
        const stateName = STATE_MAP[state.value].toLowerCase();

        return stateAbbr.includes(query) ||
               stateName.includes(query);
      });
    },

    updateAddressFields(formValues, address) {
      formValues.city = address.city || address.town || address.village || '';

      // Handle state conversion
      const stateCode = address.state_code;
      const stateName = address.state;

      // Set state
      if (stateCode) {
        const validStateCode = this.getStateAbbr(stateCode);
        if (validStateCode) {
          formValues.state = validStateCode;
        }
      } else if (stateName) {
        const validStateCode = this.getStateAbbr(stateName);
        if (validStateCode) {
          formValues.state = validStateCode;
        } else {
          console.warn(`Unable to map state: ${stateName}`);
          formValues.state = '';
        }
      } else {
        formValues.state = '';
      }

      // Always set postal code
      formValues.postalCode = address.postcode || '';
    },

    async validateAddressFields() {
      const fields = Object.keys(this.modelValidations);
      const validations = fields.map(field => this.$validator.validate(field));
      const results = await Promise.all(validations);
      return results.every(result => result === true);
    }
  }
};
