<template>
  <div
    class="form-group mb-3"
    :class="{
      'input-group-focus': focused,
      'has-label': label
    }"
  >
    <slot name="label">
      <label v-if="label" class="input-label d-flex" :style="labelStyle">
        <span>{{ label }}{{ !required ? ' (optional)' : '' }}</span>
        <div v-if="tooltip" class="position-relative">
          <i class="fa fa-question-circle tooltip-icon"></i>
          <span class="tooltip-text">{{ tooltip }}</span>
        </div>
      </label>
    </slot>
    <div
      class="input-wrapper mb-0"
      :class="{
        'input-group': hasIcon || $slots.addonRight
      }"
    >
      <slot name="addonLeft">
        <span v-if="addonLeftIcon" class="input-group-prepend">
          <div
            class="input-group-text"
            :class="{
              'error-brdr': hasError,
              'success-brdr': !hasError && touched
            }"
          >
            <i :class="addonLeftIcon"></i>
          </div>
        </span>
      </slot>
      <input
        ref="dateInput"
        v-focus="focus"
        type="text"
        :class="inputClass"
        v-model="displayDate"
        v-maska="dateMask"
        :placeholder="placeholder || 'MM-DD-YYYY'"
        :disabled="disabled"
        :name="name"
        v-bind="$attrs"
        @input="handleInput"
        @blur="handleBlur"
        @focus="handleFocus"
        @keydown="handleKeydown"
      />
      <slot name="addonRight">
        <span class="success-icon" v-if="hasValidation && !hasError && touched">
          <i class="fa fa-check-circle" aria-hidden="true"></i>
        </span>
        <span class="error-icon" v-else-if="hasError">
          <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
        </span>
        <span v-if="addonRightIcon" class="input-group-append">
          <div
            class="input-group-text"
            :class="{
              'error-brdr': hasError,
              'success-brdr': hasValidation && !hasError && touched
            }"
          >
            <i :class="addonRightIcon"></i>
          </div>
        </span>
      </slot>
    </div>
    <slot name="error" v-if="hasError || $slots.error">
      <i class="error-msg">{{ error }}</i>
    </slot>
  </div>
</template>

<script>
import { DB_COMPLETE_DATE_FORMAT, PRETTY_DATE_FORMAT } from '@/helpers/constants';
import { DateTime } from 'luxon';

export default {
  inheritAttrs: false,
  name: 'BaseDatePicker',
  props: {
    labelStyle: String,
    focus: Boolean,
    required: Boolean,
    label: { type: String },
    error: { type: String, default: null },
    value: { type: [String, Number] },
    name: { type: String, required: true },
    disabled: { type: Boolean, default: false },
    placeholder: { type: String },
    addonLeftIcon: { type: String },
    addonRightIcon: { type: String },
    hasValidation: { type: Boolean, default: true },
    tooltip: { type: String, default: '' }
  },
  data() {
    return {
      focused: false,
      touched: false,
      displayDate: this.value || '',
      dateMask: {
        mask: '##-##-####',
        preprocessor: (value) => {
          if (!value) return value;

          // Remove non-digits and dashes
          value = value.replace(/[^\d-]/g, '');

          // Handle yyyy-MM-dd format
          if (value.match(/^\d{4}/) || value.startsWith('20')) {
            if (value.length >= 4 && !value.includes('-')) {
              value = `${value.slice(0, 4)}-${value.slice(4)}`;
            }
            if (value.length >= 7 && value.split('-').length === 2) {
              value = `${value.slice(0, 7)}-${value.slice(7)}`;
            }
            return value.slice(0, 10);
          }

          // Handle MM-dd-yyyy format
          if (value.length >= 2 && !value.includes('-')) {
            value = `${value.slice(0, 2)}-${value.slice(2)}`;
          }
          if (value.length >= 5 && value.split('-').length === 2) {
            value = `${value.slice(0, 5)}-${value.slice(5)}`;
          }
          return value.slice(0, 10);
        }
      }
    };
  },
  computed: {
    hasError() {
      return !!this.error && this.error.trim().length > 0;
    },
    hasIcon() {
      const { addonLeft, addonRight } = this.$slots;
      return (
        addonLeft !== undefined ||
        this.addonLeftIcon !== undefined ||
        addonRight !== undefined ||
        this.addonRightIcon !== undefined
      );
    },
    inputClass() {
      return {
        'form-control': true,
        'error-brdr': this.hasError,
        'success-brdr': this.hasValidation && !this.hasError && this.touched
      };
    }
  },
  methods: {
    handleInput(evt) {
      const { value } = evt.target;
      this.touched = true;
      this.$emit('input', value);

      // Clear validation errors if field is empty or incomplete
      if (!value) {
        if (this.required) {
          this.$emit('validation-error', 'This field is required');
        } else {
          this.$emit('validation-success');
        }
        return;
      }

      // Only validate complete dates
      if (value.length === 10) {
        this.validateDate(value);
      } else {
        // Clear validation state for incomplete dates
        this.$emit('validation-success');
      }
    },

    handleKeydown(event) {
      if (event.key !== 'Enter') {
        return;
      }
      event.preventDefault();
      const value = this.displayDate;

      if (!value) {
        if (this.required) {
          this.$emit('validation-error', 'This field is required');
        }
        return;
      }

      if (value.length < 10) {
        this.$emit('validation-error', 'Please enter a complete date');
        return;
      }

      if (this.validateDate(value)) {
        this.$emit('switch-focus');
      }
    },

    handleBlur() {
      this.focused = false;
      const value = this.displayDate;

      if (!value) {
        if (this.required) {
          this.$emit('validation-error', 'This field is required');
        }
        this.$emit('blur');
        return;
      }

      if (value.length < 10) {
        this.$emit('validation-error', 'Please enter a complete date');
        this.$emit('blur');
        return;
      }

      this.validateDate(value);
      this.$emit('blur');
    },

    handleFocus() {
      this.focused = true;
      this.$emit('focus');
    },

    validateDate(value) {
      if (!value || value.length < 10) return false;

      // Try both date formats
      let dateObj = DateTime.fromFormat(value, PRETTY_DATE_FORMAT);
      if (!dateObj.isValid) {
        dateObj = DateTime.fromFormat(value, DB_COMPLETE_DATE_FORMAT);
      }

      if (!dateObj.isValid) {
        this.$emit('validation-error', 'Please use MM-DD-YYYY or YYYY-MM-DD format');
        return false;
      }

      // Check date range validation
      if (dateObj.startOf('day') > DateTime.now().startOf('day')) {
        this.$emit('validation-error', 'Date cannot be in the future');
        return false;
      }

      // Convert to pretty format if it was in DB format
      if (value.match(/^\d{4}-\d{2}-\d{2}$/)) {
        this.displayDate = dateObj.toFormat(PRETTY_DATE_FORMAT);
        this.$emit('input', this.displayDate);
      }

      this.$emit('validation-success');
      return true;
    },

    reset() {
      this.touched = false;
      this.displayDate = '';
      this.$emit('input', '');
      this.$emit('validation-success');
    },

    setFocus() {
      this.$refs.dateInput?.focus();
    }
  },
  watch: {
    value(newValue) {
      if (newValue !== this.displayDate) {
        this.displayDate = newValue;
        if (newValue && newValue.length === 10) {
          this.validateDate(newValue);
        }
      }
    }
  },
  directives: {
    focus: {
      inserted: function (el, { value }, { context }) {
        context.$nextTick(() => {
          if (value) el.focus();
        });
      }
    }
  }
};
</script>

<style scoped>
.error {
  display: block;
  width: 100%;
  font-size: 80%;
  color: #ff1721 !important;
  margin-top: 5px;
}

.tooltip-icon {
  margin-left: 5px;
  cursor: help;
}

.tooltip-text {
  visibility: hidden;
  width: 350px;
  background-color: #322f35;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-icon:hover + .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.form-control {
  height: 40px !important;
  max-height: 40px !important;
}
</style>