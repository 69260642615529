import { DateTime } from 'luxon';
import { DB_COMPLETE_DATE_FORMAT, PRETTY_DATE_FORMAT } from '../helpers/constants';

export const dateMixin = {
  data() {
    return {
      dateMask: {
        mask: '##-##-####',
        preprocessor: (value) => {
          if (!value) return value;

          // Remove non-digits and dashes
          value = value.replace(/[^\d-]/g, '');

          // Handle yyyy-MM-dd format
          if (value.match(/^\d{4}/) || value.startsWith('20')) {
            if (value.length >= 4 && !value.includes('-')) {
              value = `${value.slice(0, 4)}-${value.slice(4)}`;
            }
            if (value.length >= 7 && value.split('-').length === 2) {
              value = `${value.slice(0, 7)}-${value.slice(7)}`;
            }
            return value.slice(0, 10);
          }

          // Handle MM-dd-yyyy format
          if (value.length >= 2 && !value.includes('-')) {
            value = `${value.slice(0, 2)}-${value.slice(2)}`;
          }
          if (value.length >= 5 && value.split('-').length === 2) {
            value = `${value.slice(0, 5)}-${value.slice(5)}`;
          }
          return value.slice(0, 10);
        }
      }
    };
  },

  methods: {
    validateDate(value) {
      if (!value || value.length < 10) return { isValid: false };

      // Try both date formats
      let dateObj = DateTime.fromFormat(value, PRETTY_DATE_FORMAT);
      if (!dateObj.isValid) {
        dateObj = DateTime.fromFormat(value, DB_COMPLETE_DATE_FORMAT);
      }

      if (!dateObj.isValid) {
        return {
          isValid: false,
          error: 'Please use MM-DD-YYYY or YYYY-MM-DD format'
        };
      }

      // Check if date is in the future
      if (dateObj.startOf('day') > DateTime.now().startOf('day')) {
        return {
          isValid: false,
          error: 'Date cannot be in the future'
        };
      }

      // Convert to pretty format if it was in DB format
      if (value.match(/^\d{4}-\d{2}-\d{2}$/)) {
        return {
          isValid: true,
          formattedDate: dateObj.toFormat(PRETTY_DATE_FORMAT)
        };
      }

      return { isValid: true };
    },

    formatDBDateToPretty(dbDate) {
      if (!dbDate) return '';
      const date = DateTime.fromFormat(dbDate, DB_COMPLETE_DATE_FORMAT);
      return date.isValid ? date.toFormat(PRETTY_DATE_FORMAT) : dbDate;
    },

    formatPrettyToDBDate(prettyDate) {
      if (!prettyDate) return '';
      const date = DateTime.fromFormat(prettyDate, PRETTY_DATE_FORMAT);
      return date.isValid ? date.toFormat(DB_COMPLETE_DATE_FORMAT) : prettyDate;
    }
  }
};