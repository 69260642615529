import 'perfect-scrollbar/css/perfect-scrollbar.css';

import PerfectScrollbar from 'perfect-scrollbar';

const initScrollbar = (ref, options = {}) => {
  const defaultOptions = {
    suppressScrollX: true,
    suppressScrollY: false,
    wheelPropagation: false,
    swipeEasing: true,
    scrollingThreshold: 1000,
    wheelSpeed: 1,
  };

  const mergedOptions = { ...defaultOptions, ...options };
  return new PerfectScrollbar(ref, mergedOptions);
};

const destroyScrollbar = (psInstance) => {
  if (psInstance) {
    psInstance.destroy();
  }
};


const scrollToElement = (targetElement) => {
  if (!targetElement) return;

  const container = targetElement.closest('.ps') || document.documentElement;
  const containerTop = container.getBoundingClientRect().top;
  const targetTop = targetElement.getBoundingClientRect().top - containerTop + container.scrollTop;
  const centerOffset = (container.clientHeight - targetElement.clientHeight) / 2;

  container.scrollTo(0, targetTop - centerOffset);
};

export { initScrollbar, destroyScrollbar, scrollToElement };
