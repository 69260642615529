var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group mb-3",class:{
    'input-group-focus': _vm.focused,
    'has-label': _vm.label
  }},[_vm._t("label",function(){return [(_vm.label)?_c('label',{staticClass:"input-label d-flex",style:(_vm.labelStyle)},[_c('span',[_vm._v(_vm._s(_vm.label)+_vm._s(!_vm.required ? ' (optional)' : ''))]),(_vm.tooltip)?_c('div',{staticClass:"position-relative"},[_c('i',{staticClass:"fa fa-question-circle tooltip-icon"}),_c('span',{staticClass:"tooltip-text"},[_vm._v(_vm._s(_vm.tooltip))])]):_vm._e()]):_vm._e()]}),_c('div',{staticClass:"input-wrapper mb-0",class:{
      'input-group': _vm.hasIcon || _vm.$slots.addonRight
    }},[_vm._t("addonLeft",function(){return [(_vm.addonLeftIcon)?_c('span',{staticClass:"input-group-prepend"},[_c('div',{staticClass:"input-group-text",class:{
            'error-brdr': _vm.hasError,
            'success-brdr': !_vm.hasError && _vm.touched
          }},[_c('i',{class:_vm.addonLeftIcon})])]):_vm._e()]}),_c('input',_vm._b({directives:[{name:"focus",rawName:"v-focus",value:(_vm.focus),expression:"focus"},{name:"model",rawName:"v-model",value:(_vm.displayDate),expression:"displayDate"},{name:"maska",rawName:"v-maska",value:(_vm.dateMask),expression:"dateMask"}],ref:"dateInput",class:_vm.inputClass,attrs:{"type":"text","placeholder":_vm.placeholder || 'MM-DD-YYYY',"disabled":_vm.disabled,"name":_vm.name},domProps:{"value":(_vm.displayDate)},on:{"input":[function($event){if($event.target.composing)return;_vm.displayDate=$event.target.value},_vm.handleInput],"blur":_vm.handleBlur,"focus":_vm.handleFocus,"keydown":_vm.handleKeydown}},'input',_vm.$attrs,false)),_vm._t("addonRight",function(){return [(_vm.hasValidation && !_vm.hasError && _vm.touched)?_c('span',{staticClass:"success-icon"},[_c('i',{staticClass:"fa fa-check-circle",attrs:{"aria-hidden":"true"}})]):(_vm.hasError)?_c('span',{staticClass:"error-icon"},[_c('i',{staticClass:"fa fa-exclamation-triangle",attrs:{"aria-hidden":"true"}})]):_vm._e(),(_vm.addonRightIcon)?_c('span',{staticClass:"input-group-append"},[_c('div',{staticClass:"input-group-text",class:{
            'error-brdr': _vm.hasError,
            'success-brdr': _vm.hasValidation && !_vm.hasError && _vm.touched
          }},[_c('i',{class:_vm.addonRightIcon})])]):_vm._e()]})],2),(_vm.hasError || _vm.$slots.error)?_vm._t("error",function(){return [_c('i',{staticClass:"error-msg"},[_vm._v(_vm._s(_vm.error))])]}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }