<template>
  <form
    class="d-flex flex-column column-gap-3 form-content"
    ref="form-content"
    v-scrollbar
  >
    <BaseCustomInput
      ref="input-first-name"
      label="First name"
      data-vv-as="First name"
      required
      :name="`firstName${index}`"
      :error="getError(`firstName${index}`, 'First name')"
      v-model="formValues.firstName"
      v-validate="modelValidations.firstName"
      @blur="handleInputBlur"
      @keydown.enter.prevent="focusNext('input-first-name', 'input-middle-name')"
    />
    <BaseCustomInput
      ref="input-middle-name"
      label="Middle name"
      data-vv-as="Middle name"
      :name="`middleName${index}`"
      :error="getError(`middleName${index}`, 'Middle name')"
      v-model="formValues.middleName"
      v-validate="modelValidations.middleName"
      @blur="handleInputBlur"
      @keydown.enter.prevent="focusNext('input-middle-name', 'input-last-name')"
    />
    <BaseCustomInput
      ref="input-last-name"
      label="Last name"
      data-vv-as="Last name"
      required
      :name="`lastName${index}`"
      :error="getError(`lastName${index}`, 'Last name')"
      v-model="formValues.lastName"
      v-validate="modelValidations.lastName"
      @blur="handleInputBlur"
      @keydown.enter.prevent="focusNext('input-last-name', 'input-title')"
    />
    <BaseSelect
      ref="input-title"
      label="Title"
      data-vv-as="Title"
      required
      hasValidation
      :name="`title${index}`"
      :error="getError(`title${index}`, 'Title')"
      :options="titleOptions"
      v-model="formValues.title"
      v-validate="modelValidations.title"
      @blur="handleInputBlur"
      @switch-focus="focusNext('input-title', 'position-input')"
    />
    <BaseSelect
      ref="position-input"
      label="Position"
      data-vv-as="Position"
      required
      hasValidation
      :name="`position${index}`"
      :options="positionOptions"
      :error="getError(`position${index}`)"
      v-model="formValues.position"
      v-validate="modelValidations.position"
      @blur="handleInputBlur"
      @switch-focus="focusNext('position-input', 'numeric-input')"
    />
    <BaseNumericInput
      v-if="type === IDENTITY_TYPE.BUSINESS"
      ref="numeric-input"
      label="Ownership Percentage"
      :name="`ownershipPercentage${index}`"
      :error="getError(`ownershipPercentage${index}`, 'Ownership')"
      v-model="formValues.ownershipPercentage"
      :min="0"
      :max="100"
      :precision="0"
      currency="%"
      currencyPosition="suffix"
      data-vv-as="Ownership Percentage"
      required
      hasValidation
      v-validate="modelValidations.ownershipPercentage"
      @blur="handleInputBlur"
      @switch-focus="focusNext('numeric-input', 'input-date-of-birth')"
    />
    <BaseDatePicker
      ref="input-date-of-birth"
      label="Date of Birth"
      data-vv-as="Date of Birth"
      required
      :name="`birthDate${index}`"
      :error="getError(`birthDate${index}`, 'Date of Birth')"
      v-model="formValues.birthDate"
      v-validate="modelValidations.birthDate"
      @blur="handleInputBlur"
      @validation-error="handleDateValidationError"
      @validation-success="handleDateValidationSuccess"
      @switch-focus="focusNext('input-date-of-birth', 'input-phone')"
      tooltip="Enter your date of birth in MM-DD-YYYY format"
    />
    <BasePhoneInput
      ref="input-phone"
      label="Phone"
      data-vv-as="Phone number"
      required
      :name="`phoneNumber${index}`"
      :error="getError(`phoneNumber${index}`, 'Phone number')"
      v-model="formValues.phoneNumber"
      v-validate="modelValidations.phoneNumber"
      @blur="handleInputBlur"
      @keydown.enter.prevent="focusNext('input-phone', 'input-email')"
    />
    <BaseCustomInput
      ref="input-email"
      label="Email"
      data-vv-as="Email"
      required
      :name="`email${index}`"
      :error="getError(`email${index}`, 'Email')"
      v-model="formValues.email"
      v-validate="modelValidations.email"
      @blur="handleInputBlur"
      @keydown.enter.prevent="focusNext('input-email', 'input-address')"
    />
    <BaseSelect
      ref="input-address"
      label="Address"
      data-vv-as="Address"
      required
      hasValidation
      :name="`address${index}`"
      :error="getError(`address${index}`, 'Address')"
      v-model="selectedAddress"
      v-validate="{
        required: true,
        mustHaveStreetNumber: true,
        mustHaveStreetName: true
      }"
      :isRemoteSearch="true"
      :remoteMethod="handleAddressSearch"
      :loading="addressLoading"
      placeholder="Type to search address"
      @blur="handleInputBlur"
      @change="handleAddressSelect"
      @input="handleAddressInput"
      :validationClasses="{
        'is-valid': !errors.has(`address${index}`) && selectedAddress,
        'is-invalid': errors.has(`address${index}`)
      }"
      :showValidationIcon="true"
      @keydown.enter.prevent="focusNext('input-address', 'input-city')"
      useInputTextFallback
      tabindex="0"
    />
    <BaseCustomInput
      ref="input-city"
      label="City"
      data-vv-as="City"
      required
      :name="`address.city${index}`"
      :error="getError(`address.city${index}`, 'City')"
      v-model="formValues.address.city"
      v-validate="modelValidations['address.city']"
      @blur="handleInputBlur"
      @keydown.enter.prevent="focusNext('input-city', 'input-state')"
    />
    <BaseSelect
      ref="input-state"
      label="State"
      data-vv-as="State"
      required
      hasValidation
      filterable
      :name="`address.state${index}`"
      :options="stateOptions"
      :error="getError(`address.state${index}`, 'State')"
      v-model="formValues.address.state"
      v-validate="modelValidations['address.state']"
      @switch-focus="focusNext('input-state', 'input-zip')"
    />
    <BaseCustomInput
      ref="input-zip"
      label="Zip Code"
      data-vv-as="Zip Code"
      required
      :name="`address.postalCode${index}`"
      :error="getError(`address.postalCode${index}`, 'Zip Code')"
      v-model="formValues.address.postalCode"
      v-validate="modelValidations['address.postalCode']"
      @blur="handleInputBlur"
      @keydown.enter.prevent="focusNext('input-zip', 'input-country')"
    />
    <BaseSelect
      ref="input-country"
      label="Country"
      data-vv-as="Country"
      required
      disabled
      :name="`address.country${index}`"
      :options="[{ value: 'US', label: 'US' }]"
      v-model="formValues.address.country"
      v-validate="modelValidations['address.country']"
      addonRightIcon="fa fa-lock"
      @switch-focus="focusNext('input-country', 'input-ssn')"
    />
    <BaseCustomInput
      style="z-index: 2"
      ref="input-ssn"
      label="Social Security number (SSN)"
      data-vv-as="SSN"
      required
      :name="`ssn${index}`"
      :error="getError(`ssn${index}`, 'SSN')"
      v-maska="'###-##-####'"
      v-model="formValues.ssn"
      v-validate="modelValidations.ssn"
      @blur="handleInputBlur"
      @keydown.enter.prevent="submitForm"
    />
  </form>
</template>

<script>
import BaseNumericInput from '@/components/BaseNumericInput.vue';
import {
  dateLessThanTomorrow,
  mustHaveStreetName,
  mustHaveStreetNumber,
  validBirthDate,
  validDateFormat,
  validPhoneFormat
} from '@/helpers/CustomValidations';
import { addressMixin } from '@/mixins/AddressMixin';
import { dateMixin } from '@/mixins/DateMixin';
import { formControlMixin } from '@/mixins/FormControlMixin';
import { DateTime } from 'luxon';
import {
  DB_COMPLETE_DATE_FORMAT,
  IDENTITY_TYPE,
  OWNERSHIP_TYPES,
  PRETTY_DATE_FORMAT,
  PRETTY_DATE_FORMAT_DP
} from '../helpers/constants';

export default {
  name: 'IndividualSubForm',
  components: { BaseNumericInput },
  inject: ['$validator'],
  props: ['type', 'person', 'index', 'ownershipType'],
  mixins: [formControlMixin, addressMixin, dateMixin],
  data() {
    return {
      formValues: {
        firstName: '',
        middleName: '',
        lastName: '',
        title: '',
        position: '',
        ownershipPercentage: null,
        birthDate: '',
        phoneNumber: '',
        email: '',
        ssn: '',
        address: {
          streetNum: '',
          streetName: '',
          city: '',
          state: '',
          postalCode: '',
          country: 'US'
        }
      },
      addressLoading: false,
      selectedAddress: null,
      IDENTITY_TYPE,
      OWNERSHIP_TYPES,
      titleOptions: [
        { label: 'Mr.', value: 'Mr.' },
        { label: 'Ms.', value: 'Ms.' },
        { label: 'Mrs.', value: 'Mrs.' }
      ],
      PRETTY_DATE_FORMAT_DP,
      modelValidations: {
        firstName: { required: true, max: 24, regex: /^[a-zA-Z0-9 _\-&.',]*$/ },
        middleName: { max: 24, regex: /^[a-zA-Z0-9 _\-&.',]*$/ },
        lastName: { required: true, max: 24, regex: /^[a-zA-Z0-9 _\-&.',]*$/ },
        title: { required: true },
        position: { required: true },
        ownershipPercentage: { required: true, min_value: 0, max_value: 100 },
        birthDate: { required: true, date_format: PRETTY_DATE_FORMAT, validBirthDate: true },
        phoneNumber: { required: true, validPhoneFormat: true },
        email: { required: true, email: true, max: 70 },
        ssn: { required: true, regex: /^\d{3}-\d{2}-\d{4}$/ },
        // Address component validations
        'address.city': { required: true, max: 30 },
        'address.state': { required: true, length: 2 },
        'address.postalCode': { required: true, max: 30, regex: /^[0-9]{5}(-[0-9]{4})?$/ },
        'address.country': { required: true, length: 2, regex: /^[A-Z]{2}$/ }
      }
    };
  },
  computed: {
    minBirthDate() {
      return DateTime.now().minus({ years: 100 }).toJSDate();
    },
    maxBirthDate() {
      return DateTime.now().minus({ years: 18 }).toJSDate();
    },
    formattedBirthDate: {
      get() {
        let { birthDate } = this.formValues;

        if (!birthDate) {
          console.error('birthDate is undefined or null');
          return '';
        }

        const fromDBFormat = DateTime.fromFormat(birthDate, DB_COMPLETE_DATE_FORMAT);
        const fromPrettyFormat = DateTime.fromFormat(birthDate, PRETTY_DATE_FORMAT);

        if (fromDBFormat.isValid) {
          return fromDBFormat.toFormat(PRETTY_DATE_FORMAT);
        } else if (!fromPrettyFormat.isValid) {
          console.error('Invalid date format', birthDate);
        }

        return birthDate;
      },
      set(value) {
        this.$set(this.formValues, 'birthDate', value);
      }
    },
    positionOptions() {
      switch (this.ownershipType) {
        case OWNERSHIP_TYPES.PRIVATE_CORP:
        case OWNERSHIP_TYPES.LLC:
        case OWNERSHIP_TYPES.NON_PROFIT:
        case OWNERSHIP_TYPES.PARTNERSHIP:
        case OWNERSHIP_TYPES.TRUST:
        case OWNERSHIP_TYPES.LIMITED_PARTNERSHIP:
        case OWNERSHIP_TYPES.LIMITED_LIABILITY_PARTNERSHIP:
          return [
            { value: 'OW', label: 'Owner' },
            { value: 'CEO', label: 'CEO' },
            { value: 'CFO', label: 'CFO' },
            { value: 'COO', label: 'COO' },
            { value: 'PRESIDENT', label: 'President' },
            { value: 'VICE PRESIDENT', label: 'Vice President' },
            { value: 'DIR', label: 'Director' },
            { value: 'TR', label: 'Treasurer' },
            { value: 'SEC', label: 'Secretary' },
            { value: 'PA', label: 'Partner' },
            { value: 'MEMBER LLC', label: 'Member LLC' },
            { value: 'OTH', label: 'Other' }
          ];
        case OWNERSHIP_TYPES.SOLE_PROP:
          return [{ value: 'SP', label: 'Sole Proprietorship' }];
        default:
          return [];
      }
    }
  },
  created() {
    const hasStreetNum = this.person?.address?.streetNum != null;
    const hasStreetName = this.person?.address?.streetName != null;
    const addressValue = hasStreetNum && hasStreetName
      ? `${this.person.address.streetNum} ${this.person.address.streetName}`
      : '';

    // Initialize form values without triggering validation
    this.formValues = {
      firstName: this.person?.firstName || '',
      middleName: this.person?.middleName || '',
      lastName: this.person?.lastName || '',
      title: this.person?.title || '',
      position: this.person?.position || '',
      ownershipPercentage: this.person?.ownershipPercentage || 0,
      birthDate: this.person?.birthDate || '',
      phoneNumber: this.person?.phoneNumber || '',
      email: this.person?.email || '',
      ssn: this.person?.ssn || '',
      address: {
        streetNum: this.person?.address?.streetNum || this.person?.streetNum || '',
        streetName: this.person?.address?.streetName || this.person?.streetName || '',
        city: this.person?.address?.city || this.person?.city || '',
        state: this.person?.address?.state || this.person?.state || '',
        postalCode: this.person?.address?.postalCode || this.person?.postalCode || '',
        country: this.person?.address?.country || this.person?.country || 'US'
      }
    };

    // Initialize selectedAddress if we have address components
    if (hasStreetNum && hasStreetName) {
      this.selectedAddress = addressValue;
    }

    // Register custom validators without immediate validation
    const validators = {
      validPhoneFormat,
      mustHaveStreetName,
      mustHaveStreetNumber,
      validDateFormat,
      dateLessThanTomorrow,
      validBirthDate
    };

    Object.entries(validators).forEach(([name, validator]) => {
      if (!this.$validator.rules[name]) {
        this.$validator.extend(name, validator.obj || validator);
      }
    });
  },
  mounted() {
    // Handle demo mode
    if (this.$route.params.spProductSlug?.slice(-5) === '-demo') {
      this.$nextTick(() => {
        for (const key in this.$refs) {
          if (key.indexOf('input-') > -1) this.$refs[key]._data.touched = true;
        }
      });
    }

    // Set initial position if available
    if (this.positionOptions[0]?.value != null) {
      this.formValues.position = this.positionOptions[0].value;
    }

    // Let FormFocusMixin handle initial focus
    this.$nextTick(() => {
      this.setupFocusHandlers();
      // Initial focus on first input without validation
      const firstInput = this.$refs['input-first-name'];
      if (firstInput && typeof firstInput.focus === 'function') {
        firstInput.focus();
      }
    });
  },
  methods: {
    getData() {
      // Create a new object without _id and extract address fields
      const { _id, address, ...formData } = this.formValues;

      // Return data with address fields at root level
      return {
        ...formData,
        streetNum: address?.streetNum || '',
        streetName: address?.streetName || '',
        city: address?.city || '',
        state: address?.state || '',
        postalCode: address?.postalCode || '',
        country: address?.country || 'US'
      };
    },
    handleInputBlur(e) {
      // Skip validation for sole proprietorship during input
      if (this.ownershipType === OWNERSHIP_TYPES.SOLE_PROP) {
        this.$emit('onInputBlur');
        return;
      }

      // Get the field name from the event target
      const field = e?.target?.name;
      if (!field) {
        this.$emit('onInputBlur');
        return;
      }

      // For address field, validate both combined and individual fields
      if (field === `address${this.index}`) {
        this.$validator.validate(field).then(() => {
          // Only validate individual fields if the combined field is valid
          if (!this.errors.has(field)) {
            const addressFields = [
              'address.city',
              'address.state',
              'address.postalCode',
              'address.country'
            ];

            addressFields.forEach(addressField => {
              this.$validator.validate(`${addressField}${this.index}`);
            });
          }
          this.$emit('onInputBlur');
        });
      } else {
        // For other fields, just validate the current field
        this.$validator.validate(field).then(() => {
          this.$emit('onInputBlur');
        });
      }
    },
    getError(fieldName, fieldLabel) {
      // Handle nested field names for address
      const isAddressField = fieldName.startsWith('address.');
      const actualFieldName = isAddressField ? `${fieldName}${this.index}` : fieldName;

      // Only show errors for touched fields
      if (!this.$validator.flags[actualFieldName]?.touched) return null;

      const error = this.$validator.errors.first(actualFieldName);
      if (!error) return null;

      // If error already contains the field label, return as is
      if (error.includes(fieldLabel)) return error;

      // For required field errors, format with field label
      if (error.includes('is required')) {
        return `${fieldLabel} is required`;
      }

      // For other errors, prepend the field label
      return `${fieldLabel} ${error}`;
    },
    getError(fieldName, fieldLabel) {
      return this.errors.first(fieldName)
        ? this.errors.first(fieldName).replace(` ${fieldLabel}`, '')
        : null;
    },
    submitForm() {
      // Only validate all fields on form submission
      this.$validator.validateAll().then(isValid => {
        if (isValid) {
          this.$emit('onSubmit');
        }
      });
    },
    handleDateValidationError(error) {
      if (error) {
        this.$validator.errors.add({
          field: `birthDate${this.index}`,
          msg: error,
          scope: null,
          rule: 'date'
        });
      }
    },
    handleDateValidationSuccess() {
      this.$validator.errors.remove(`birthDate${this.index}`);
    },
    handleAddressInput(value) {
      // Always update selectedAddress immediately
      this.selectedAddress = value;

      if (typeof value !== 'string') {
        return;
      }
      // Split on first space to separate street number and name
      const parts = value.trim().split(/\s+/);
      const streetNum = parts[0] || '';
      const streetName = parts.slice(1).join(' ');

      // Update address fields immediately
      this.formValues.address = {
        ...this.formValues.address,
        streetNum,
        streetName
      };

      // Trigger validation immediately
      this.$nextTick(() => {
        this.$validator.validate(`address${this.index}`);
      });
    },
    handleAddressSelect(selected) {
      if (!selected?.value?.address) return;

      const { address } = selected.value;
      const formattedAddress = this.formatAddress(address);
      this.selectedAddress = formattedAddress;

      // Split address into components internally
      const [streetNum, ...streetNameParts] = formattedAddress.split(' ');
      const streetName = streetNameParts.join(' ');

      // Get state code from address
      const stateCode = this.getStateAbbr(address.state_code || address.state);

      // Store address fields in the nested address object
      this.formValues.address = {
        streetNum: streetNum || '',
        streetName: streetName || '',
        city: address.city || address.town || address.village || '',
        state: stateCode || '',
        postalCode: address.postcode || '',
        country: 'US'
      };

      // Validate the combined address field first
      this.$validator.validate(`address${this.index}`).then(() => {
        // Then validate the individual address components
        const addressFields = [
          'address.city',
          'address.state',
          'address.postalCode',
          'address.country'
        ];

        addressFields.forEach(field => {
          this.$validator.validate(`${field}${this.index}`);
        });
      });
    }
    // getPreviousInput(currentElement) {
    //   const inputs = Array.from(
    //     this.$el.querySelectorAll('input, select, textarea, button')
    //   );
    //   const currentIndex = inputs.indexOf(currentElement);
    //   return currentIndex > 0 ? inputs[currentIndex - 1] : null;
    // }
  },
  watch: {
    formValues: {
      handler() {
        this.$emit('change', this.formValues);
      },
      deep: true
    },
    positionOptions() {
      this.formValues.position = null;
    }
  }
};
</script>
