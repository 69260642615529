<template>
  <header id="header">
    <div v-if="logoImgUrl" id="logo-container">
      <div id="logo-background">
        <img
          :src="logoImgUrl"
          alt="Product"
          @load="setBackground"
          @error="handleImageError"
        />
      </div>
    </div>
    <nav class="header-progress">
      <div class="flex-spacer"></div>
      <div class="steps-container" ref="steps-container">
        <ul class="progress-steps">
          <li
            v-for="(item, index) in stepsWithArrows"
            :key="index"
            :ref="item.type === 'step' ? `step-${item.originalIndex}` : undefined"
            :class="{
              'progress-step': item.type === 'step',
              'arrow-step': item.type === 'arrow',
              visited: item.isVisited && item.type === 'step',
              'non-clickable': index === 0 && item.type === 'step'
            }"
            @click="
              index !== 0 && item.isVisited
                ? navigateTo(item.routeNames[0], item.originalIndex)
                : null
            "
          >
            <template v-if="item.type === 'step'">
              <i
                class="d-block fa-fw"
                :class="item.icon"
                :ref="`icon-${item.originalIndex}`"
                :style="{ 'margin-right': item.gap + 'px' }"
              ></i>
              <span>{{ item.type === 'step' && item.name === 'Verify Ownership' ? headerTitle : item.name }}</span>
            </template>
            <template v-if="item.type === 'arrow'">
              <i class="fa fa-arrow-right" :class="{ 'arrow-spacing': true }"></i>
            </template>
          </li>
        </ul>
      </div>
      <div class="flex-spacer"></div>
      <div class="progress-container">
        <div class="progress-line" :style="{ width: progressWidth }"></div>
      </div>
    </nav>
    <div class="flex-spacer"></div>
  </header>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'HeaderComponent',
  data() {
    return {
      defaultGap: 8,
      progressWidth: '0%',
      brandingInfo: this.getBrandingInfo(),
      currentStep: 0,
      headerTitle: 'Verify Ownership',
      activeTab: 'company'
    };
  },
  computed: {
    ...mapGetters({
      facilitator: 'getFacilitator',
      hasSignificantChanges: 'getHasSignificantChanges',
      lastSignificantChangeStep: 'getLastSignificantChangeStep',
      steps: 'getSteps'
    }),
    visitedStepsCount() {
      return this.steps.reduce(
        (lastIndex, step, index) => (step.isVisited ? index : lastIndex),
        -1
      );
    },
    stepsWithArrows() {
      const result = [];
      this.steps.forEach((step, index) => {
        result.push({
          ...step,
          type: 'step',
          gap: this.defaultGap,
          originalIndex: index
        });
        if (index < this.steps.length - 1) {
          result.push({ type: 'arrow' });
        }
      });
      return result;
    },
    logoImgUrl() {
      const branding = this.brandingInfo;
      return branding?.logoUrl
        ? `/static/${branding.logoUrl}`
        : `/static/${this.facilitator?.branding?.rectLarge || ''}`;
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        this.updateRouteAndSteps(to.name);
        this.updateHeaderTitle(to);
      }
    },
    hasSignificantChanges: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.handleSignificantChanges();
        }
      }
    },
    steps: {
      deep: true,
      handler(newSteps, oldSteps) {
        const lastCompletedStep = newSteps.reduce((lastCompleted, step, index) => {
          if (step.isCompleted) {
            return index;
          }
          return lastCompleted;
        }, -1);

        if (lastCompletedStep !== -1) {
          this.updateCurrentStep(newSteps[lastCompletedStep].routeNames[0]);
        }
      }
    }
  },
  methods: {
    ...mapActions(['SET_SIGNIFICANT_CHANGES', 'MARK_STEPS_VISITED']),

    navigateTo(routeName, stepIndex) {
      if (stepIndex === 0) {
        return;
      }

      const isVisitedOrPrevious =
        this.steps[stepIndex].isVisited || stepIndex <= this.currentStep;

      if (!(routeName && isVisitedOrPrevious)) {
        return;
      }

      this.$router
        .push({ name: routeName })
        .catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            console.error('Navigation error:', err);
          }
        })
        .catch(() => {});

      this.markStepsAsVisited(stepIndex);
      this.updateCurrentStep(routeName);
    },

    resetVisitedSteps(fromStep) {
      this.$nextTick(() => {
        const updatedSteps = this.steps.map((step, index) => {
          if (index > fromStep) {
            return { ...step, isVisited: false };
          }
          return step;
        });
        this.$store.commit('SET_STEPS', updatedSteps);
      });
    },

    markStepsAsVisited(upToIndex, setVisited = true) {
      const updatedSteps = this.steps.map((step, index) => {
        if (index <= upToIndex) {
          return { ...step, isVisited: setVisited };
        }
        return step;
      });
      this.$store.commit('SET_STEPS', updatedSteps);
    },

    calculateProgressWidth() {
      this.$nextTick(() => {
        if (this.steps.length < 1) {
          this.progressWidth = '0px';
          return;
        }
        const currentStepRefs = this.$refs[`step-${this.currentStep}`];
        if (
          !currentStepRefs ||
          (Array.isArray(currentStepRefs) && currentStepRefs.length === 0)
        ) {
          return;
        }
        const currentStepRef = Array.isArray(currentStepRefs)
          ? currentStepRefs[0]
          : currentStepRefs;
        const stepRect = currentStepRef.getBoundingClientRect();
        const stepsContainer = this.$refs['steps-container'];
        if (!stepsContainer) {
          return;
        }
        const containerRect = stepsContainer.getBoundingClientRect();
        const progressWidth = stepRect.right - containerRect.left;
        this.progressWidth = `calc(${progressWidth}px + 20px)`;
      });
    },

    updateVisitedSteps(routeName) {
      const stepIndex = this.steps.findIndex(step => step.routeNames.includes(routeName));
      if (stepIndex === 0) {
        this.markStepsAsVisited(this.steps.length - 1, false);
      }
      if (stepIndex !== -1) {
        this.markStepsAsVisited(stepIndex);
      }
    },

    getBrandingInfo() {
      const storedBranding = localStorage.getItem('branding');
      let branding = null;
      if (storedBranding) {
        try {
          branding = JSON.parse(storedBranding);
        } catch (error) {
          console.error('Failed to parse branding from localStorage', error);
        }
      }
      return branding;
    },

    setBackground() {
      const imgElement = this.$el.querySelector('#logo-container img');
      if (!imgElement.complete || imgElement.naturalHeight === 0) {
        console.error('Image not loaded or has no dimensions');
        return;
      }

      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      if (!context) {
        console.error('Unable to get canvas context');

        imgElement.style.width = '100%';
        imgElement.style.height = '100%';
        imgElement.style.maxHeight = '100px';
        imgElement.style.objectFit = 'fill';

        return;
      }

      canvas.width = imgElement.naturalWidth;
      canvas.height = imgElement.naturalHeight;
      context.drawImage(imgElement, 0, 0, canvas.width, canvas.height);

      // Analyze the edge pixels to determine the predominant background color
      const edgeData = this.extractEdgePixels(context, canvas.width, canvas.height);

      const backgroundColor = this.calculateBackgroundColor(edgeData);
      this.$el.querySelector('#logo-container').style.backgroundColor = backgroundColor;
    },

    extractEdgePixels(context, width, height) {
      const edgeThickness = 10; // Define the thickness of the edge to analyze
      const leftEdge = context.getImageData(
        0,
        edgeThickness,
        edgeThickness,
        height - 2 * edgeThickness
      ).data;

      return [...leftEdge];
    },

    calculateBackgroundColor(pixels) {
      const colorCount = {};
      let maxCount = 0;
      let backgroundColor = 'transparent';

      for (let i = 0; i < pixels.length; i += 4) {
        const alpha = pixels[i + 3];
        if (alpha === 0) {
          this.$el.querySelector('#logo-container').style.backgroundColor = 'transparent';
          return 'transparent'; // Early return if any pixel is fully transparent
        }

        const colorHex = `#${(
          (1 << 24) +
          (pixels[i] << 16) +
          (pixels[i + 1] << 8) +
          pixels[i + 2]
        )
          .toString(16)
          .slice(1)}`;
        colorCount[colorHex] = (colorCount[colorHex] || 0) + 1;

        if (colorCount[colorHex] > maxCount) {
          maxCount = colorCount[colorHex];
          backgroundColor = colorHex;
        }
      }

      return backgroundColor;
    },

    updateCurrentStep(routeName) {
      const stepIndex = this.steps.findIndex(s => s.routeNames.includes(routeName));
      if (stepIndex === -1) return;

      const stepMeta = this.$route.meta?.stepIndex;
      this.currentStep = typeof stepMeta === 'number' ? stepMeta : stepIndex;

      sessionStorage.setItem('currentStep', this.currentStep.toString());
      this.calculateProgressWidth();
    },

    handleSignificantChanges() {
      if (this.lastSignificantChangeStep === null) {
        return;
      }
      this.resetVisitedSteps(this.lastSignificantChangeStep);
      this.calculateProgressWidth();
    },

    updateRouteAndSteps(routeName) {
      this.updateCurrentStep(routeName);
      this.updateVisitedSteps(routeName);
      this.brandingInfo = this.getBrandingInfo();
    },

    handleImageError(event) {
      const { target } = event;
      target.style.display = 'none'; // Hide the image
    },
    updateHeaderTitle(route) {
      const storedTitle = localStorage.getItem('headerTitle');
      if (storedTitle) {
        this.headerTitle = storedTitle;
      }
    },
    handleTabChange(newTab) {
      this.activeTab = newTab;
      this.updateHeaderTitle(this.$route);
    }
  },
  mounted() {
    this.calculateProgressWidth();
    this.markStepsAsVisited(this.currentStep - 1);
    this.updateCurrentStep(this.$route.name);
    this.updateHeaderTitle(this.$route);

    // Listen for merchant type changes
    this.$root.$on('merchant-type-changed', (newTab) => {
      const title = newTab === 'government' ? 'Contact Information' : 'Verify Ownership';
      this.headerTitle = title;
      localStorage.setItem('headerTitle', title);
    });
  },
  beforeDestroy() {
    // Clean up event listener
    this.$root.$off('merchant-type-changed');
  },
};
</script>

<style lang="scss">
#header {
  display: flex;
  justify-content: center;
  align-items: stretch;
  min-height: 100px;
  background-color: #f1f1f1;

  #logo-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    & > #logo-background {
      padding: 5px;
      margin: 0 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & > #logo-background > img {
      width: auto;
      max-width: 200px;
      height: 100%;
      max-height: 40px;
      display: block;
      object-fit: contain;
    }
  }

  .header-progress {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    max-width: 968px;
    min-width: 100%;
    @media (min-width: 968px) {
      min-width: 968px;
    }
    flex: 1;
  }

  .flex-spacer {
    flex: 1;
  }

  .steps-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .progress-steps {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }

  .progress-step {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;

    &.visited i,
    &.visited span {
      color: black;
    }

    &:not(.visited) {
      cursor: not-allowed;
    }

    i {
      height: 20px;
      font-size: 20px;
    }

    span {
      font-size: 14px;
    }

    &:not(.visited) i,
    &:not(.visited) span {
      color: #8e8787;
    }

    &.non-clickable {
      cursor: not-allowed;
    }
  }

  .arrow-spacing {
    margin: 0 8px;
    font-size: 12px !important;
    color: #767676;
  }

  .arrow-step {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .progress-container {
    position: relative;
    width: 100%;
    height: 4px;
    background-color: #ddd;

    .progress-line {
      position: absolute;
      height: 4px;
      background-color: var(--main-primary-color-lighter, unset);
      transition: width 0.3s ease;
      max-width: 968px;
    }
  }
}

@media (max-width: 767px) {
  #header {
    max-height: 50px;
    min-height: 50px;
    .progress-steps {
      li {
        span {
          display: none;
        }
        padding: 5px;
        margin: 0 2px;
      }
      .progress-step,
      .arrow-step {
        justify-content: center;
        align-items: center;
        display: flex;
        i {
          margin-right: 0 !important;
        }
      }
    }
    .arrow-spacing {
      margin: 0;
    }
  }
}
</style>
