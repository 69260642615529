<template>
  <section v-if="identity.type" class="main-body-wrapper" id="company-form">
    <div class="page-title w-100">
      <div class="d-flex align-items-center mb-3">
        <h1 style="flex: 0">Add your business info</h1>
        <span
          style="flex: 0 1 auto"
          v-if="business.ownershipType"
          class="ownership-type-badge"
        >
          {{ formattedOwnershipType }}
        </span>
      </div>
      <span class="description">
        We use this information to verify your business in accordance with bank identity
        and compliance policies.
      </span>
    </div>
    <div class="form-outer-wrap form-container">
      <div class="form-wrapper form-company-info">
        <div class="container-fluid px-0">
          <form @submit.prevent="next">
            <div
              class="d-flex flex-column align-items-stretch m-0 form-content"
              ref="form-content"
              v-scrollbar
            >
              <BaseCustomInput
                v-if="!isSoleProprietor"
                ref="input-legal-name"
                name="legalName"
                label="Business Legal Name"
                data-vv-as="Legal name"
                required
                :error="getError('legalName', 'Legal name')"
                v-model="business.legalName"
                v-validate="modelValidations.business.legalName"
                @blur="partialUpdateIdentity()"
                @keydown.enter.prevent="focusNext('input-legal-name', 'input-business-name')"
                tabindex="0"
              />
              <BaseCustomInput
                ref="input-business-name"
                name="dbaName"
                label="Doing Business As (DBA)"
                tooltip="The name under which your business conducts its operations and engages with the public."
                data-vv-as="Doing business as"
                required
                :error="getError('dbaName', 'Doing business as')"
                v-model="business.dbaName"
                v-validate="modelValidations.business.dbaName"
                @blur="partialUpdateIdentity()"
                @keydown.enter.prevent="
                  focusNext('input-business-name', 'input-billing-descriptor')
                "
                tabindex="0"
              />
              <BaseCustomInput
                ref="input-billing-descriptor"
                name="billingDescriptor"
                label="Billing descriptor"
                tooltip="The name that will appear on your customers' credit card statements."
                data-vv-as="Billing descriptor"
                :error="getError('billingDescriptor', 'Billing descriptor')"
                v-model="business.billingDescriptor"
                v-validate="modelValidations.business.billingDescriptor"
                @blur="partialUpdateIdentity()"
                @keydown.enter.prevent="
                  focusNext('input-billing-descriptor', 'input-tin-type')
                "
                tabindex="0"
              />
              <BaseSelect
                ref="input-tin-type"
                required
                name="tinType"
                label="Tin Type"
                data-vv-as="Tin Type"
                hasValidation
                v-model="business.tinType"
                v-validate="modelValidations.business.tinType"
                :options="filteredTinTypeOptions"
                :error="getError('tinType', 'Tin Type')"
                @blur="partialUpdateIdentity()"
                @switch-focus="focusNext('input-tin-type', 'input-tax-id')"
                tabindex="0"
                v-if="showTinTypeField"
              >
                <template #label>
                  <label class="input-label d-flex">
                    <span>Tin Type</span>
                    <a
                      href="https://www.irs.gov/individuals/international-taxpayers/taxpayer-identification-numbers-tin"
                      target="_blank"
                      class="info-link"
                      rel="noopener noreferrer"
                      tabindex="-1"
                    >
                      <i class="fa fa-external-link-alt"></i>
                    </a>
                  </label>
                </template>
              </BaseSelect>
              <BaseCustomInput
                ref="input-tax-id"
                name="federalTaxId"
                :label="tinTypeLabel"
                :data-vv-as="'TIN'"
                required
                :error="getError('federalTaxId', business.tinType !== '1' ? (business.tinType !== '2' ? (business.tinType === '3' ? 'ITIN' : 'TIN') : 'EIN') : 'SSN')"
                v-maska="tinTypeMask"
                v-model="business.federalTaxId"
                v-validate="{ required: true, regex: tinTypeMaskRegex }"
                @blur="partialUpdateIdentity()"
                @keydown.enter.prevent="focusNext('input-tax-id', 'input-phone-number')"
                tabindex="0"
              />
              <small
                v-if="business.tinType === '2'"
                class="form-text text-muted"
                :style="getError('federalTaxId', 'EIN') ? '' : 'margin-top: 0px; margin-bottom: 7px'"
              >
                Ensure that the business legal name and Employer Identification Number
                (EIN) provided precisely match IRS documentation (e.g., SS-4 Confirmation
                letter, CP-575, or 147C).
              </small>

              <BasePhoneInput
                ref="input-phone-number"
                label="Phone"
                name="phoneNumber"
                data-vv-as="Phone"
                required
                :error="getError('phoneNumber', 'Phone')"
                v-model="business.phoneNumber"
                v-validate="modelValidations.business.phoneNumber"
                @blur="partialUpdateIdentity()"
                @keydown.enter.prevent="focusNext('input-phone-number', 'input-address')"
                tabindex="0"
              />
              <div class="d-grid grid-columns-2 column-gap-5 w-100">
                <BaseSelect
                  ref="input-address"
                  name="line"
                  label="Address"
                  data-vv-as="Address"
                  required
                  hasValidation
                  :error="getError('line', 'Address')"
                  v-model="selectedAddress"
                  v-validate="modelValidations.business.address.line"
                  :isRemoteSearch="true"
                  :remoteMethod="handleAddressSearch"
                  :loading="addressLoading"
                  placeholder="Type to search address"
                  @blur="partialUpdateIdentity()"
                  @change="handleAddressSelect"
                  @input="handleAddressInput"
                  :validationClasses="{
                    'is-valid': !errors.has('line') && selectedAddress,
                    'is-invalid': errors.has('line')
                  }"
                  :showValidationIcon="true"
                  @keydown.enter.prevent="focusNext('input-address', 'input-city')"
                  useInputTextFallback
                  tabindex="0"
                />

                <BaseCustomInput
                  ref="input-city"
                  name="city"
                  label="City"
                  data-vv-as="City"
                  required
                  :error="getError('city', 'City')"
                  v-model="business.address.city"
                  v-validate="modelValidations.business.address.city"
                  @blur="partialUpdateIdentity()"
                  @keydown.enter.prevent="focusNext('input-city', 'input-state')"
                  tabindex="0"
                />

                <BaseSelect
                  ref="input-state"
                  name="state"
                  required
                  label="State"
                  data-vv-as="State"
                  hasValidation
                  :options="stateOptions"
                  :error="getError('state', 'State')"
                  v-model="business.address.state"
                  v-validate="modelValidations.business.address.state"
                  @switch-focus="focusNext('input-state', 'input-zip-code')"
                  filterable
                  tabindex="0"
                />

                <BaseCustomInput
                  ref="input-zip-code"
                  name="postalCode"
                  label="Zip code"
                  data-vv-as="Zip code"
                  required
                  :error="getError('postalCode', 'Zip code')"
                  v-maska="'#####'"
                  v-model="business.address.postalCode"
                  v-validate="modelValidations.business.address.postalCode"
                  @blur="partialUpdateIdentity()"
                  @keydown.enter.prevent="
                    focusNext('input-zip-code', 'input-established-date')
                  "
                  tabindex="0"
                />

                <BaseSelect
                  ref="input-country"
                  name="country"
                  label="Country"
                  required
                  disabled
                  :options="[{ value: 'US', label: 'US' }]"
                  v-model="business.address.country"
                  addonRightIcon="fa fa-lock"
                  tabindex="-1"
                />

                <BaseDatePicker
                  ref="input-established-date"
                  name="businessEstablishedDate"
                  label="Business Established Date"
                  data-vv-as="Business Established Date"
                  required
                  :error="getError('businessEstablishedDate', 'Business Established Date')"
                  v-model="business.businessEstablishedDate"
                  v-validate="modelValidations.business.businessEstablishedDate"
                  @blur="partialUpdateIdentity"
                  @switch-focus="focusNext('input-established-date', 'input-expected-monthly-volume')"
                  tabindex="0"
                />
              </div>
              <BaseCustomInput
                ref="input-expected-monthly-volume"
                name="expectedMonthlyVolume"
                label="Expected Monthly Credit Card Volume"
                data-vv-as="Credit Card Volume"
                required
                :error="getError('expectedMonthlyVolume', 'Credit Card Volume')"
                v-model="business.expectedMonthlyVolume"
                v-validate="modelValidations.business.expectedMonthlyVolume"
                @blur="partialUpdateIdentity()"
                @keydown.enter.prevent="
                  focusNext(
                    'input-expected-monthly-volume',
                    'input-select-merchant-category'
                  )
                "
                tabindex="0"
              />
              <BaseSelect
                ref="input-select-merchant-category"
                name="mccCode"
                required
                label="Merchant Category"
                hasValidation
                data-vv-as="Merchant Category"
                :options="spProductMccCodes"
                :error="getError('mccCode', 'Merchant Category')"
                v-model="business.mccCode"
                v-validate="modelValidations.business.mccCode"
                @switch-focus="
                  focusNext(
                    'input-select-merchant-category',
                    'input-select-website-protocol'
                  )
                "
                tabindex="0"
              />
              <div class="d-flex flex-column w-100" style="z-index: 2">
                <label class="input-label">
                  <span>Website Link</span>
                  <i class="required-field float-left" />
                </label>
                <div class="d-flex">
                  <div class="col-3 px-0">
                    <BaseSelect
                      ref="input-select-website-protocol"
                      class="hyper"
                      tabindex="-1"
                      :options="[
                        { value: 'http', label: 'http' },
                        { value: 'https', label: 'https' }
                      ]"
                      v-model="certificate"
                      @switch-focus="
                        focusNext('input-select-website-protocol', 'input-website-link')
                      "
                    />
                  </div>
                  <div class="col-9 px-0">
                    <BaseCustomInput
                      ref="input-website-link"
                      class="web-link"
                      style="min-height: 0"
                      name="websiteUrl"
                      data-vv-as="Website Link"
                      :error="getError('websiteUrl', 'Website Link')"
                      v-model="business.websiteUrl"
                      v-validate="modelValidations.business.websiteUrl"
                      @blur="partialUpdateIdentity()"
                      @keydown.enter.prevent="
                        focusNext('input-website-link', 'submit-button')
                      "
                      tabindex="0"
                    />
                  </div>
                </div>
              </div>
            </div>
            <button
              ref="submit-button"
              type="submit"
              class="btn continue-button"
              :disabled="isSubmitting"
              tabindex="0"
            >
              {{ isSubmitting ? 'Processing...' : 'Continue' }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  IDENTITY_TYPE,
  OWNERSHIP_TYPES,
  PRETTY_DATE_FORMAT,
  TIN_TYPE_OPTIONS
} from '@/helpers/constants';
import {
  dateLessThanTomorrow,
  mustHaveStreetName,
  mustHaveStreetNumber,
  validfederalTaxId,
  validPhoneFormat,
  validUrl,
  validzipcodeRegex
} from '@/helpers/CustomValidations';
import { debounce, improvedStructuredClone } from '@/helpers/utils';
import { addressMixin } from '@/mixins/AddressMixin';
import { dateMixin } from '@/mixins/DateMixin';
import { formControlMixin } from '@/mixins/FormControlMixin';
import { DateTime } from 'luxon';
import { mapGetters } from 'vuex';

export default {
  name: 'CompanyForm',
  $_veeValidate: { validator: 'new' },
  mixins: [formControlMixin, addressMixin, dateMixin],
  data() {
    return {
      identity: {},
      business: {
        address: { line: '' },
        country: 'US',
        tinType: ''
      },
      certificate: 'https',
      spProductMccCodes: [],
      IDENTITY_TYPE,
      TIN_TYPE_OPTIONS,
      PRETTY_DATE_FORMAT,
      isSubmitting: false,
      loader: null,
      tinTypeMask: '',
      isLoading: true
    };
  },
  computed: {
    ...mapGetters(['getSaveAndExit']),
    isNotIdentityPublic() {
      return this.identity.type !== IDENTITY_TYPE.GOVERNMENT;
    },
    isNotIdentityIndividual() {
      return !(
        this.identity.type === IDENTITY_TYPE.INDIVIDUAL ||
        this.business.ownershipType === OWNERSHIP_TYPES.SOLE_PROP
      );
    },
    modelValidations() {
      return {
        business: {
          legalName: {
            required: this.isNotIdentityIndividual,
            max: 24,
            regex: /^[a-zA-Z0-9 _\-&.',]*$/
          },
          dbaName: {
            required: true,
            regex: /^[a-zA-Z0-9 _\-&.',]*$/,
            max: 50
          },
          billingDescriptor: {
            required: false,
            regex: /^[a-zA-Z0-9 _\-&.',]*$/,
            max: 25
          },
          federalTaxId: {
            required: true,
            regex: validfederalTaxId,
            max: 9
          },
          phoneNumber: {
            required: true,
            validPhoneFormat: true,
            max: 10
          },
          address: {
            line: {
              required: true,
              mustHaveStreetNumber: true,
              mustHaveStreetName: true,
              max: 40
            },
            streetNum: {
              required: true,
              max: 9,
              numeric: true
            },
            streetName: {
              required: true,
              max: 40
            },
            city: {
              required: true,
              max: 30
            },
            state: {
              required: true,
              length: 2
            },
            postalCode: {
              required: true,
              regex: validzipcodeRegex,
              max: 30
            },
            country: {
              required: true
            }
          },
          businessEstablishedDate: {
            required: true,
            validDateFormat: true,
            dateLessThanTomorrow: true
          },
          expectedMonthlyVolume: {
            required: true,
            decimal: 2,
            max_value: 10000000.0,
            min_value: 0.01
          },
          mccCode: {
            required: true
          },
          websiteUrl: {
            required: true,
            validUrl: true,
            max: 70
          },
          tinType: {
            required: this.isNotIdentityPublic
          }
        }
      };
    },
    formattedOwnershipType() {
      if (!this.business.ownershipType) return '';
      return this.business.ownershipType
        .replace(/([A-Z])(?=[a-z])/g, ' $1')
        .replace(/^./, str => str.toUpperCase())
        .trim();
    },
    tinTypeLabel() {
      const tinTypeOption = TIN_TYPE_OPTIONS.find(
        option => option?.value === this.business?.tinType
      );
      switch (tinTypeOption?.value) {
        case '1':
          this.tinTypeMask = '###-##-####';
          this.partialUpdateIdentity();
          return 'Social Security Number (SSN)';
        case '2':
          this.tinTypeMask = '##-#######';
          this.partialUpdateIdentity();
          return 'Employer Identification Number (EIN)';
        case '3':
          this.tinTypeMask = '#########';
          this.partialUpdateIdentity();
          return 'ITIN';
        default:
          this.tinTypeMask = '';
          this.partialUpdateIdentity();
          return 'TIN';
      }
    },
    tinTypeMaskRegex() {
      switch (this.tinTypeMask) {
        case '###-##-####':
          return /^\d{3}-\d{2}-\d{4}$/;
        case '##-#######':
          return /^\d{2}-\d{7}$/;
        case '#########':
          return /^\d{9}$/;
        default:
          return /.*/;
      }
    },
    showTinTypeField() {
      // Business entities (except sole prop) don't get to choose TIN type
      if (this.identity.type === IDENTITY_TYPE.BUSINESS &&
          this.business.ownershipType !== OWNERSHIP_TYPES.SOLE_PROP) {
        // Set default TIN type for businesses
        this.$nextTick(() => {
          this.business.tinType = TIN_TYPE_OPTIONS[1].value; // EIN
        });
        return false;
      }

      // Government entities and sole proprietors get to choose
      return true;
    },
    filteredTinTypeOptions() {
      // Business entities (except sole prop) only get EIN
      if (this.identity.type === IDENTITY_TYPE.BUSINESS &&
          this.business.ownershipType !== OWNERSHIP_TYPES.SOLE_PROP) {
        return TIN_TYPE_OPTIONS.filter(option => option.value === TIN_TYPE_OPTIONS[1].value); // Only EIN
      }

      // Sole proprietors get SSN and ITIN
      if (this.business.ownershipType === OWNERSHIP_TYPES.SOLE_PROP) {
        return TIN_TYPE_OPTIONS.filter(option =>
          [TIN_TYPE_OPTIONS[0].value, TIN_TYPE_OPTIONS[2].value].includes(option.value)
        ); // Only SSN and ITIN
      }

      // Government entities get all options
      return TIN_TYPE_OPTIONS;
    },
    isSoleProprietor() {
      return this.business?.ownershipType === OWNERSHIP_TYPES.SOLE_PROP;
    }
  },
  async created() {
    this.partialUpdateIdentity = debounce(this.dispatchPartialUpdate, 1500);
    try {
      const response = await this.$axios({
        url: '/onboarding/accounts/identity',
        method: 'GET'
      });
      if (['review', 'approved'].includes(response.data.status)) {
        this.$router
          .replace({ name: 'GetStarted', params: { _normalPush: true } })
          .catch(() => {});
      }
      this.identity = {
        type: response.data.identity?.type,
        business: response.data.identity?.business
      };
      this.business = this.identity.business;
      if (this.business.websiteUrl) {
        const websiteUrl = this.business.websiteUrl.split('://');
        if (websiteUrl[0] == 'http' || websiteUrl[0] == 'https') {
          this.certificate = websiteUrl[0];
          this.business.websiteUrl = websiteUrl[1];
        }
      }
      this.$set(
        this.business.address,
        'line',
        this.business.address.streetNum && this.business.address.streetName
          ? `${this.business.address.streetNum} ${this.business.address.streetName}`
          : ''
      );
      if (this.business.address.line) {
        this.selectedAddress = this.business.address.line;
      }
      if (this.business.businessEstablishedDate) {
        const formattedDate = this.formatDBDateToPretty(this.business.businessEstablishedDate);
        this.$set(
          this.business,
          'businessEstablishedDate',
          formattedDate === 'Invalid DateTime' ? this.business.businessEstablishedDate : formattedDate
        );
      } else {
        this.$set(this.business, 'businessEstablishedDate', undefined);
      }
      if (!this.business.expectedMonthlyVolume) {
        this.$set(this.business, 'expectedMonthlyVolume', undefined);
      }
      this.$set(this.business.address, 'country', 'US');
      this.$store.commit('SET_SM_APPLICATION_ID', response.data._id);
    } catch (e) {
      const errorStatus = e.response.status;
      const errorMessage = e.response.data.message
        ? e.response.data.message
        : 'Server side error, please contact the support team.';
      this.$store.commit('LOG_ERROR', { errorMessage, errorStatus });
      if (errorStatus === 401) {
        this.$router
          .replace({ name: 'GetStarted', params: { _normalPush: true } })
          .catch(() => {});
      }
    } finally {
      this.isLoading = false;
    }
  },
  async mounted() {
    const dt = DateTime;
    const dateFormat = PRETTY_DATE_FORMAT;
    this.$validator.extend(validPhoneFormat.name, validPhoneFormat.obj);
    this.$validator.extend(mustHaveStreetName.name, mustHaveStreetName.obj);
    this.$validator.extend(mustHaveStreetNumber.name, mustHaveStreetNumber.obj);
    this.$validator.extend(validUrl.name, validUrl.obj);
    this.$validator.extend('validDateFormat', {
      validate(value) {
        if (!value) return true;
        return dt.fromFormat(value, dateFormat).isValid;
      },
      getMessage: () => 'Use MM-DD-YYYY format'
    });
    this.$validator.extend('dateLessThanTomorrow', dateLessThanTomorrow.obj);
    await this.getSpProductMccCodes();
    this.$store.commit('MARK_STEP_COMPLETED', 1);
    if (this.$route.params.spProductSlug.slice(-5) === '-demo') {
      for (const key in this.$refs) {
        if (key.indexOf('input-') > -1) this.$refs[key]._data.touched = true;
      }
    }
    this.setupFocusHandlers();
    if (this.isLoading) {
      this.$nextTick(() => {
        this.loader = this.$loading.show({
          container: this.$refs['form-content'] || this.$el
        });
      });
    }
    this.$nextTick(() => {
      if (!this.isLoading) {
        const firstInput = this.identity.type === IDENTITY_TYPE.INDIVIDUAL ? 'input-business-name' : 'input-legal-name';
        this.focusNext(null, firstInput);
      }
    });
  },
  beforeDestroy() {
    this.cleanupFocusHandlers();
  },
  methods: {
    async next() {
      if (this.isSubmitting) return;

      this.isSubmitting = true;
      try {
        const isValid = await this.$validator.validateAll();

        if (!isValid) {
          this.focusFirstErrorField();
          this.$toasted.show('There are form errors, please check', {
            type: 'error',
            duration: 3000,
            position: 'top-right'
          });
          this.isSubmitting = false;
          return;
        }

        // Then validate date specifically
        const dateValidation = this.validateDate(this.business.businessEstablishedDate);
        if (!dateValidation.isValid) {
          this.handleValidationError('businessEstablishedDate', dateValidation.error);
          this.isSubmitting = false;
          return;
        }

        // Proceed with form submission
        this.partialUpdateIdentity.cancel();
        try {
          const data = this.getDataToUpdate();
          await this.$axios({
            url: '/onboarding/accounts/identity',
            method: 'PUT',
            data
          });
          this.$store.commit('MARK_STEP_COMPLETED', 2);
          await this.$router.push({
            query: {
              ...this.$route.query,
              form: this.isNotIdentityPublic ? 'individual' : 'contact'
            },
            name: 'VerifyOwnership',
            params: { _normalPush: true }
          });
        } catch (error) {
          this.handleError(error);
        }
      } catch (error) {
        console.error('Form validation failed:', error);
      } finally {
        this.isSubmitting = false;
      }
    },
    saveAndExit() {
      this.partialUpdateIdentity.cancel();
      this.dispatchPartialUpdate();
      this.$router
        .push({ name: 'ApplicationSaved', params: { _normalPush: true } })
        .catch(() => {});
    },
    setStreetNumAndStreetNameAndDeleteAddress(obj, type = 'individual') {
      if (type === 'business') {
        const splittedAddress = obj.address.line.split(' ');
        delete obj.address.line;
        obj.address.streetNum = splittedAddress[0];
        splittedAddress.shift();
        obj.address.streetName = splittedAddress.join(' ');
        return;
      }
      const splittedAddress = obj.address.split(' ');
      delete obj.address;
      obj.streetNum = splittedAddress[0];
      splittedAddress.shift();
      obj.streetName = splittedAddress.join(' ');
    },
    dispatchPartialUpdate() {
      const data = this.getDataToUpdate();
      if (data.identity.business.tinType === '') {
        delete data.identity.business.tinType;
      }
      this.$axios({
        url: '/onboarding/accounts/identity/autosave',
        method: 'PUT',
        data
      });
    },
    getDataToUpdate() {
      const data = { identity: improvedStructuredClone(this.identity) };
      data.identity.business = improvedStructuredClone(this.business);

      data.identity.business.expectedMonthlyVolume = Number(
        data.identity.business.expectedMonthlyVolume
      );

      data.identity.business.websiteUrl = `${this.certificate}://${data.identity.business.websiteUrl}`;

      this.setStreetNumAndStreetNameAndDeleteAddress(data.identity.business, 'business');

      data.identity.business.federalTaxId = data.identity.business.federalTaxId.replace(
        /-/gi,
        ''
      );

      if (data.identity.business.businessEstablishedDate) {
        const formattedDate = this.formatPrettyToDBDate(data.identity.business.businessEstablishedDate);
        if (formattedDate !== 'Invalid DateTime') {
          data.identity.business.businessEstablishedDate = formattedDate;
        }
      }

      return data;
    },
    async getSpProductMccCodes() {
      const response = await this.$axios({
        url: `/onboarding/spproducts/${this.$route.params.spProductSlug}/mcc`,
        method: 'GET'
      });
      if (Array.isArray(response.data))
        this.spProductMccCodes = response.data.map(m => ({
          label: `${m.value} - ${m.description}`,
          value: m.value
        }));
    },
    handleAddressSelect(value) {
      if (!value || typeof value !== 'object' || !value.value?.address) return;

      const { address } = value.value;
      const formattedAddress = this.formatAddress(address);
      this.updateAddressFields(this.business.address, address);
      this.selectedAddress = formattedAddress;
      this.business.address.line = formattedAddress;
      this.validateAddressFields();

      this.$nextTick(() => {
        this.focusNext('input-address', 'input-city');
      });
    },
    getError(fieldName, fieldLabel) {
      const error = this.errors.first(fieldName);
      if (!error) return null;

      // Check if the error is related to federalTaxId and tinType
      if (fieldName === 'federalTaxId' && this.business.tinType) {
        const { tinTypeLabel } = this; // Get the full name of the TIN type

        if (error.includes('required')) {
          return `${tinTypeLabel} is required`;
        } else if (error.includes('format is invalid')) {
          return `${tinTypeLabel} format is invalid`;
        } else if (error.includes('length should be')) {
          return `${tinTypeLabel} ${error.split(' ').slice(3).join(' ')}`; // Use the remaining part of the error message
        }
      }

      // For other errors or if tinType is not set, use the default logic
      if (error.includes(fieldLabel)) return error;
      if (error.includes('is required')) return `${fieldLabel} is required`;
      return `${fieldLabel} ${error}`;
    },
    handleError(error) {
      const errorStatus = error.response?.status;
      const errorMessage = error.response?.data?.message || 'Server side error, please contact the support team.';

      this.$store.commit('LOG_ERROR', { errorMessage, errorStatus });
      if (errorStatus === 401) {
        this.$router.replace({ name: 'GetStarted', params: { _normalPush: true } });
      }
      this.$toasted.show(errorMessage, {
        type: 'error',
        duration: 3000,
        position: 'top-right'
      });
    },
    handleAddressInput(value) {
      console.log('handleAddressInput', value);
      if (typeof value === 'string') {
        const [streetNum, ...streetNameParts] = value.split(' ');
        const streetName = streetNameParts.join(' ');

        // Immediately update all address fields
        this.$set(this.business.address, 'line', value);
        this.$set(this.business.address, 'streetNum', streetNum);
        this.$set(this.business.address, 'streetName', streetName);
        this.selectedAddress = value;

        // Validate immediately
        this.$validator.validate('line');
        return;
      }
      this.selectedAddress = value;
    }
  },
  watch: {
    getSaveAndExit(newValue) {
      if (newValue) {
        this.saveAndExit();
      }
    },
    isLoading(newVal) {
      if (!newVal && this.loader) {
        this.loader.hide();
        this.$nextTick(() => {
          const firstInput = this.identity.type === IDENTITY_TYPE.INDIVIDUAL ? 'input-business-name' : 'input-legal-name';
          this.focusNext(null, firstInput);
        });
      }
    },
    addressLoading(newVal, oldVal) {
      if (oldVal && !newVal) {
        this.$nextTick(() => {
          const select = this.$refs['input-address'];
          if (!select?.$refs.select) {
            return;
          }
          const dropdown = select.$refs.select.$el.querySelector('.el-select-dropdown__wrap');
          const firstOption = dropdown?.querySelector('.el-select-dropdown__item');
          if (firstOption) {
            firstOption.classList.add('hover');
            firstOption.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
          }
        });
      }
    },
    selectedAddress(newVal) {
      if (!newVal || typeof newVal === 'string') return;

      const { address } = newVal;
      if (!address) return;

      const formattedAddress = this.formatAddress(address);
      this.updateAddressFields(this.business.address, address);

      this.$nextTick(() => {
        this.selectedAddress = formattedAddress;
      });

      this.validateAddressFields();
    }
  }
};
</script>

<style lang="scss">
#company-form {
  display: flex;
  flex-direction: column;
  align-items: center;

  .el-input__inner,
  .mx-input {
    background-color: transparent !important;
  }

  .page-title {
    text-align: left;
    max-width: 480px;
    width: 100%;
    & > div {
      white-space: nowrap;
    }
  }

  .ownership-type-badge {
    display: inline-block;
    margin-left: 20px;
    padding: 5px 8px;
    font-size: 0.75rem;
    font-weight: 500;
    color: var(--titles-color);
    background-color: var(--main-primary-color-lighter, unset);
    border-radius: 4px;
    line-height: 14px;
    vertical-align: middle;
  }

  .form-container {
    margin-bottom: 0;
  }

  .hyper {
    .el-input__inner {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      padding: 0 12px;
    }
  }

  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 1.2rem;
    color: #666;
  }

  .info-link {
    display: inline-flex;
    align-items: center;
    margin-left: 0.5rem;
    color: #666;
    font-size: 0.8rem;
    text-decoration: none;
    transition: color 0.2s ease;
    cursor: pointer;
    vertical-align: middle;
    &:hover {
      color: var(--primary);
    }
  }

  // Add styles for autocomplete dropdown
  .pac-container {
    z-index: 9999;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid #dcdfe6;
    background-color: #fff;

    .pac-item {
      padding: 8px 12px;
      cursor: pointer;

      &:hover {
        background-color: #f5f7fa;
      }
    }
  }

  .address-suggestions {
    position: absolute;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    background: white;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    z-index: 9999;
    margin-top: 4px;

    .suggestion-item {
      padding: 8px 12px;
      cursor: pointer;
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        background-color: #f5f7fa;
      }
    }
  }

  .suggestion-label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  // Override Element UI select styles to match your theme
  .el-select-dropdown__item {
    padding: 8px 12px;

    &.selected {
      color: var(--primary);
      font-weight: bold;
    }

    &:hover {
      background-color: #f5f7fa;
    }
  }
}

</style>