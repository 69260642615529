var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"owner-detail d-flex justify-content-between"},[_c('button',{ref:"editButton",staticClass:"btn edit-button",on:{"click":function($event){return _vm.$router.push({ name: 'VerifyOwnership' })}}},[_c('i',{staticClass:"fas fa-pencil-alt"})])]),_c('div',{staticClass:"d-grid grid-columns-2 gap-4"},[_c('div',[_vm._m(0),_c('span',[_vm._v(_vm._s(_vm.individual.firstName))])]),_c('div',[_vm._m(1),_c('span',[_vm._v(_vm._s(_vm.individual.middleName || 'N/A'))])]),_c('div',[_vm._m(2),_c('span',[_vm._v(_vm._s(_vm.individual.lastName))])]),_c('div',[_c('label',{attrs:{"for":_vm.isContact ? 'position' : 'title'}},[_c('span',[_vm._v(_vm._s(_vm.isContact ? 'Position' : 'Title'))])]),_c('span',[_vm._v(_vm._s(_vm.isContact ? _vm.individual.position : (_vm.individual.title || 'N/A')))])]),_c('div',[_vm._m(3),_c('span',[_vm._v(_vm._s(_vm.individual.birthDate || 'N/A'))])]),_c('div',[_vm._m(4),_c('span',[_vm._v(_vm._s(_vm.individual.phoneNumber))])]),_c('div',[_vm._m(5),_c('span',[_vm._v(_vm._s(_vm.individual.email))])]),_c('div',[_vm._m(6),_c('span',[_vm._v(_vm._s(_vm.individual.ssn))])]),(!_vm.isContact && _vm.type === 'business')?_c('div',[_vm._m(7),_c('span',[_vm._v(_vm._s(_vm.individual.ownershipPercentage)+"%")])]):_vm._e(),(!_vm.isContact)?_c('div',[_vm._m(8),_c('span',[_vm._v(_vm._s(_vm.formatAddress(_vm.individual)))])]):_vm._e(),(!_vm.isContact)?_c('div',[_vm._m(9),_c('span',[_vm._v(_vm._s(_vm.individual.city))])]):_vm._e(),(!_vm.isContact)?_c('div',[_vm._m(10),_c('span',[_vm._v(_vm._s(_vm.individual.state))])]):_vm._e(),(!_vm.isContact)?_c('div',[_vm._m(11),_c('span',[_vm._v(_vm._s(_vm.individual.postalCode))])]):_vm._e(),_c('div',[_vm._m(12),_c('span',[_vm._v(_vm._s(_vm.individual.country || 'US'))])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"firstName"}},[_c('span',[_vm._v("First name")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"middleName"}},[_c('span',[_vm._v("Middle name")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"lastName"}},[_c('span',[_vm._v("Last name")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"birthDate"}},[_c('span',[_vm._v("Date of birth")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"phoneNumber"}},[_c('span',[_vm._v("Phone")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"email"}},[_c('span',[_vm._v("Email")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"ssn"}},[_c('span',[_vm._v("SSN")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"ownershipPercentage"}},[_c('span',[_vm._v("Ownership")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"address"}},[_c('span',[_vm._v("Address")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"city"}},[_c('span',[_vm._v("City")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"state"}},[_c('span',[_vm._v("State")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"postalCode"}},[_c('span',[_vm._v("Zip code")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"country"}},[_c('span',[_vm._v("Country")])])
}]

export { render, staticRenderFns }